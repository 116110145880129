@import 'variables.scss';

html[theme] {
  .user-account-management {
    min-height: 70px;
    .loading-overlay {
      position: absolute;
    }

    .user-btn {
      margin-bottom: 10px;
    }

    .tir-ui-tbody {
      .action-buttons-column {
        width: 250px;
        text-align: center;

        .#{$ns}-button-group {
          .#{$ns}-button {
            width: 100px;
          }
        }
      }
    }
  }

  .tir-ui-structured-filter {
    .#{$ns}-popover-wrapper {
      flex: 1 1 auto;

      .#{$ns}-input-ghost {
        font-size: 13px;
      }
    }
  }
}


