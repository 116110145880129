.metric-value {
  display: inline-block;
  .value-container,
  .comparison-value-container {
    display: inline-block;
    .value {
      min-width: 45px;
      display: inline-block;
      text-align: right;
    }
    .unit {
      min-width: 20px;
      display: inline-block;
      padding-left: 4px;
    }
  }
  .value-container {
    min-width: 80px;
    text-align: right;
    // border-bottom: 1px solid red;
    .unit {
      min-width: unset;
      text-align: left;
    }
  }
}

.table-view.individual-cards tbody.tir-ui-body {
  box-shadow: none !important;
}
.table-cell-dash {
  display: inline-flex;
  justify-content: flex-end;
  min-width: 80px;
}

.ShowMore-container {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  max-height: 500px;
  overflow: auto;
}

.WidgetFactory-Table-container .ShowMore-container .WidgetTable-showmore {
  width: fit-content;
  height: fit-content;
  box-shadow: none;
  padding: 0;
  margin-right: 5px;
  margin-bottom: 16px;
}

.flipped-table {
  &#{&}#{&}#{&} {
    .bp3-html-table {
      display: flex;

      thead,
      tbody {
        display: flex;
        flex-direction: row;
      }

      th,
      td {
        display: block;
        border-bottom: 1px solid #E9ECEF;
      }

      tr {
        display: flex;
        flex-direction: column;
        width: 100%;
        &:last-child {
          td:last-child {
            border-bottom: none;
          }
        }
      }

      thead {
        order: -1;
      }

      tbody {
        order: 1;
        flex: 1 1 auto;
        box-shadow: none;
      }

      tr {
        th {
          flex: 1 1 auto;
          min-width: 235px;
          &:last-child {
            border-bottom: none;
          }
        }

        td {
          flex: 1 1 auto;
          padding-top: 11px;
          padding-bottom: 11px;
          padding-left: 70px;
          text-align: left;
          * {
            min-width: 0;
          }
        }

        &:hover {
          td {
            background-color: transparent;
          }
        }
      }
    }

    .sort-control {
      display: none;
    }
  }
}
