@import "utils/themes/common/variables";

html[theme=dark] {
    .propertiesContainer .attributes .key {
        color: $indigo5;
    }
}

html[theme=light] {
    .propertiesContainer .attributes .key {
        color: $indigo2;
    }
}

.propertiesContainer .attributes {
    word-wrap: break-word;
}

.propertiesContainer .attributes > ul {
    padding-left: 0px;
}

.propertiesContainer .attributes li {
    list-style-type: none;
}
