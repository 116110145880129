.add-widget-control {
  .add-widget-control-header {
      background: #fafafa;
      border-bottom: 2px solid lightgray;
  }
  .add-widget-control-items {
      .add-widget-control-item {
          padding-bottom: 4px;
          margin-bottom: 4px;
          cursor: pointer;
          &:last-child {
              border-bottom: none;
              padding-bottom: 0;
              margin-bottom: 8px;
          }
      }
  }
  .add-widget-control-category {
    border-radius: 5px;
    border: 2px solid #e4e4e4;
    padding: 8px 15px;
    background-color: #fff;
}
}
