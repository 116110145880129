.timeline-chart {
  cursor: text;

  $series-colors: (
    iqEvents: #0080FE,
    lifecycle: orange,
    indicators: red,
  );

  @each $index, $color in $series-colors {
    .highcharts-legend-item.#{$index} rect {
      fill: $color;
    }
  }

  .highcharts-markers {
    cursor: pointer;
  }

  .highcharts-label {
    cursor: default;
  }
}

.cta-timeline {
  display: block;
  padding-top: 5px;
}

.highcharts-data-label[style*="opacity: 1"] {
  .cta-timeline {
    cursor: pointer;
  }
}

.metric-timeline {
  display: block;
  padding-top: 5px;
}

.overflow-labels-bg {
    display: block;
    height: 100px;
    background-color: white;
    width: 100%;
    position: absolute;
    transform: translateY(100%);
    border-radius: 8px;
}

.remove-timeline-bg {
  &.card {
    background-color: transparent !important;
  }
}

html[theme=dark] {
  .overflow-labels-bg {
    background-color: #2F333E;
  }
  .highcharts-legend-item text {
    fill: white !important;
  }
  .timeline-zoom-btn {
    background: #161A25 !important;
    &:hover {
      background: #585858 !important;
    }
  }
}

.bp3-overlay:has(.timeline-dialog) {
  z-index: 110 !important;
}

.timeline-zoom-btn {
  position: absolute;
  right: 0;
  z-index: 1;
  background: white !important;
  &:hover {
    background: lightgrey !important;
  }
}

