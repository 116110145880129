@import "~@blueprintjs/core/lib/scss/variables.scss";
@import "bootstrap-variables";
$riverbed-orange: #ff6a02;
$transition-speed-1: 0.3s;
$transition-speed-2: 0.5s;

$navbar-light-brand-color:                $riverbed-orange;
$navbar-light-brand-hover-color:          $riverbed-orange;
$navbar-dark-brand-color:                 $riverbed-orange;
$navbar-dark-brand-hover-color:           $riverbed-orange;

$critical-color:  #E04B4B;
$degraded-color: #FBB700;
$normal-color: #00DB7E;
$unknown-color: #999;

$critical-priority-color: #c74140;
$high-priority-color: #f28349;
$moderate-priority-color: #fcda5d;
$low-priority-color: #e5eaef;
$unknown-priority-color: transparent;

$scrollbar-track-color: #F5F5F5;
$scrollbar-thumb-color: #333;

$sizes: map-merge(
  (
    20: 20%,
    80: 80%
  ),
  $sizes
);
