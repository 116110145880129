.selector-min-width {
  min-width: 90px;
}
  
.built-in-props-content {
  background-color: #007bff;
  padding: 0.2rem 0.6rem;
  border-radius: 0.8rem;
  color: white;
  font-weight: 400;
  width: auto;
  margin: 2px;
}

.built-in-props {
  max-width: inherit;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.transform-template-dialog{
  min-width: 670px !important;
  width: fit-content !important;
  max-width: 100vw;
  max-height: 90dvh;

  .basic-dialog-load-facade {
    overflow-y: auto !important;
  }

  textarea {
    min-width: 50px;
    max-width: 100%;
    max-height: 65vh;
  }
}

.on-demand-input-data-properties {
  span.bp3-popover-target.bp3-popover-target {
    display: block;
  }
}