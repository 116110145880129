@import 'variables.scss';

html[theme=light] .navigator-toolbar {
  position: sticky !important;
  top: 0 !important;
  background-color: #e9ecef;  
}

html[theme=dark] .navigator-toolbar {
  position: sticky !important;
  top: 0 !important;
  background-color: #242833;
}
