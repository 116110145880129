@import "utils/themes/common/variables";

html[theme=dark] {
    .propertiesContainer .profile .AWS {
        svg {
            background-color: $dark-gray4;
        }

        .themeIcon path {
            // Colors were based off what was seen on other AWS icon's colors
            fill: #FFFFFF;
        }
    }

    .propertiesContainer .profile .ONPREM {
        svg path {
            // Colors were based off what was seen on other AWS icon's colors, keeping it consistent with NetIM data
            stroke: #FFFFFF;
            fill: #FFFFFF;
            stroke-width: 0;
        }
    }
}

html[theme=light] {
    .propertiesContainer .profile .AWS {
        svg {
            background-color: $light-gray1;
        }

        .themeIcon path {
            // Colors were based off what was seen on other AWS icon's colors
            fill: #242F3E;
        }
    }

    .propertiesContainer .profile .ONPREM {
        svg path {
            // Colors were based off what was seen on other AWS icon's colors, keeping it consistent with NetIM data
            stroke: #242F3E;
            fill: #242F3E;
            stroke-width: 0;
        }
    }
}

.propertiesContainer .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.propertiesContainer .profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.propertiesContainer .profile .AWS .themeIcon,
.propertiesContainer .profile .AWS .bp3-icon svg,
.propertiesContainer .profile .ONPREM svg {
    width: 40px;
    height: 40px;
}

.propertiesContainer .profile .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
}

.propertiesContainer .profile .AWS svg {
    border-radius: 20px;
}

.propertiesContainer .profile .details {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    word-break: break-word;
}

.propertiesContainer .subLabels {
    display: flex;
    flex-direction: column;
}

.propertiesContainer hr {
    border-color: $gray1 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.propertiesContainer .body {
    max-height: 65vh;
    overflow-y: auto;
}
