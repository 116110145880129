//$tag-color: #B041DA;
//$tag-color: #E26E44;
//$tag-color: #FF9900;
//$tag-color: #3a275d;
//$tag-color: #275d4c;
$tag-color: #a0520a;
$tag-color2: #27385d;

.cloudassets-search-page {

    td.select-row,
    th.select-row-column {
        display: none;
    }

    .incident-search-table th:last-child,
    .incident-search-table td:last-child {
        display: none;
    }

    .facet-view-header.facet-view-header {
        border-bottom: none;
    }

    .facet-cta.facet-cta {
        border-top: none;
        padding: 0;
    }

    .tir-ui-table-container {
        padding-top: 0;
    }

    .facet-section-icon.facet-clear{
        display: none;
    }

    .TIRUI-Table-controls{
        display: none;
    }

    .search-header-wrapper .left-controls{
        display: none;
    }

    .cloudassets-tag-bubble {
        text-wrap: nowrap;
        border-radius: 6px;
        margin-right: 8px;
        margin-top: 3px;
        margin-bottom: 3px;
        display: inline-block;
        overflow: hidden;
        border: 2px solid $tag-color;
        background-color: $tag-color;
    }

    .cloudassets-tag-bubble{
        .cloudassets-tag-key, .cloudassets-tag-value {
            line-height: 2;
            padding: 8px;
        }

        .cloudassets-tag-key{
            padding-right: 8px;
            //background-color: $tag-color;
            color: #f5f8fa;
        }
    
        .cloudassets-tag-value{
            padding-left: 8px;
            padding: 0 8px;
            display: inline-block;
            border-radius: 6px;
            background-color: $tag-color2;
            color: #f5f8fa;
        }

        .cloudassets-tag-value:empty{
            display: none;
        }
    }

    // div[title="AWS"] rect{
    //     fill: #ff9900;
    // }
}

html[theme=dark]:has(.cloudassets-search-page, .d3-geomap){
    body {
        background: linear-gradient(-30deg,  #27385d 0%, #2f406a 40%, #111b35 100%) !important;
        //    background: #27385d;
        //   background: #2f406a;
        //  background: #111b35;
        //background: #202d4e;
    }

    .search-header-wrapper, .bg-white{
        background: transparent;
        background-color: transparent !important;
    }

    .search-facade .left-column, .main-navbar{
        background-color: #0a112e;
    }

    .sidebar, .bp3-popover .bp3-popover-content .bp3-menu{
        background-color: #27385d !important;
    }

    .sidebar .bp3-menu a.bp3-menu-item.bp3-active{
        background-color: #445e87 !important;
    }

    #right-details-pane .incident-brief-blade-container>div:first-child{
        background-color: #445e87;
    }

    .incident-brief-blade-container .blade-container-content {
        background-color: #0a112e;
    }

    .search-facade .left-column, .facet-cta, .facet-view-header{
        background-color: #0a112e;
    }

    .search-facade .left-column{
        border-radius: 0;
        margin: 0;
    }
}

html[theme=dark] .cloudassets-search-page{

    .tir-ui-table-container tbody {
        background: transparent !important;
    }

    .incident-search-table table th, .pagination-sticky{
        background: #0a112e;
    }

    .facet-section-container, .bp3-button:not([class*="bp3-intent-"]).bp3-active, .search-header-bar .bp3-button:not([class*="bp3-intent-"]) {
        background: #27385d;
    }

    .tir-ui-table-container table.tir-ui-table.bp3-html-table tr.row-selected, .tir-ui-table-container table.tir-ui-table.bp3-html-table tr:not(:first-child).row-selected{
        background-color: #445e87;
    }

    .bp3-button.bp3-intent-primary, .bp3-button.bp3-intent-success {
        background-color: #3496cb;
        border: 2px solid #3496cb;
    }

    .bp3-button.bp3-intent-primary:hover, .bp3-button.bp3-intent-success:hover {
        background-color: #27385d !important;
        border-color: #3496cb;
    }

    table.bp3-html-table.bp3-interactive.bp3-interactive tbody tr:hover td, .bp3-button.bp3-minimal:hover{
        background-color: rgba(248, 249, 250, 0.15);
    }


    .bp3-button.bp3-outlined {
        border: 1px solid #3496cb !important;
    }

    .bp3-button.bp3-outlined:hover {
        background-color: #27385d !important;
    }

    // #search-input-text{
    //     background-color: #0a112e;
    // }
}

// #0a112e//dark left

// #27385d//upper left gradient

// #2f406a//middle gradient

// #111b35//lower right gradient

// #445e87//top mid, table header/footer

// #3496cb//button light

// #6cc9fb//light blue text


// }