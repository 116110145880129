.runbook-path-traversal {
    .card-with-title-and-status {
        height: 800px !important;
    }
    .toolbar {
        box-shadow: none;
        .graph-toolbar {
            &>*,
            .ribbon+button {
                display: none !important;
            }
            .bp3-button-group {
                display: inline-flex !important;
                &>* {
                    display: none !important;
                }
                .simple-tooltip-target:has(span[icon="zoom-in"]),
                .simple-tooltip-target:has(span[icon="zoom-out"]),
                .simple-tooltip-target:has(span[icon="maximize"]) {
                    display: inline-block !important;
                }
            }
        }
    }
    .react-flow__edge {
        pointer-events: none;
    }
    .plus-container {
        pointer-events: none;
        & * {
            pointer-events: none;
        }
    }
    .node-controls {
        display: none !important;
    }
    .runbook-traversal-view-scroll-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }
    .react-flow__edge:has(path) {
        opacity: 1;
    }
    .not-traversed {
        opacity: 0.5 !important;
    }
    .node-failed:after {
        content: "Failed";
        background-color: red;
        display: block;
        font-weight: 400;
        color: #fff;
        font-size: 14px;
    }
    .node-succeeded:after {
        content: "Succeeded";
        background-color: green;
        display: block;
        font-weight: 400;
        color: #fff;
        font-size: 14px;
    }
}
.runbook-path-traversal-node-info-dialog {
    width: 660px !important;
    .blade-container {
        button {
            display: none;
        }
        input {
            pointer-events: none;
        }
        tr:has([name="node-debug-entry"]) {
            display: none;
        }
    }
    .decision-node-output {
        &:has(#add-output) {
         display: none !important;
        }
    }
    .add-output-control,
    textarea:disabled:empty {
        display: none !important;
    }
    .bp3-radio {
        pointer-events: none !important;
    }
}
.path-traversal-tabs {
    .json-viewer-debug-textarea,
    .json-viewer-error-textarea {
        font-size: 15px !important;
    }

    .bp3-control-indicator {
        opacity: 0.5;
    }
}
.has-debug .contents:after {
    content: "Debug";
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    bottom: -17px;
    width: 100%;
}
.has-subflow-errors .contents:after {
    content: "Proceeding with \A Errors";
    background-color: red;
    font-weight: 400;
    font-size: 14px;
    position: absolute;
    bottom: -34px;
    width: 100%;
    color: #fff;
    white-space: pre-wrap;
}