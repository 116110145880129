.layoutControlContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    max-height: 190px;
    overflow-y: auto;
}

.layoutControlContainer>div {
    display: flex;
    flex-direction: column;
}