html[theme] {
    .sub-header-left-section {
        >span {
            margin-right: 10px;
        }
        >div {
            margin-right: 20px;
        }
    }
}

.incident-details-side-panel {
    height: calc(100vh - 168px);
    overflow-y: auto;
}
