@import 'utils/themes/common/variables';

html[theme=dark] {
	.rich-text-editor {
		background-color: $dark-gray1;
		border: 1px solid $dark-gray5;
	}
	.rich-text-editor button {
		background: $dark-gray5;
	}
	.rich-text-editor button[aria-checked=true] {
		background: $dark-gray3;
	}
}

.texteditor-size {
	min-width: 410px;
	min-height: 260px;
}

.rich-text-editor select {
	max-width: 130px;
}

.variables-checked {
	background: #d8d8d8 !important;
}