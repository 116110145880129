@import "../../../../utils/themes//common//variables";

.secret {
    &--marked-for-deletion {
        opacity: 0.3;   
    }

    &--expired {
        .expiration-date {
            color: $critical-color;
        }
    }
}

html[theme=dark] .table tr td,
html[theme=dark] .table tr th {
    color: #f5f8fa;
}