$chart-min-width: 70px;
$chart-min-height: 70px;

$bar-chart-min-width: 200px;
$bar-chart-min-height: $chart-min-height;

$donut-chart-min-width: 100px;
$donut-chart-min-height: $donut-chart-min-width;

$sparkline-chart-min-width: 180px;
$sparkline-chart-min-height: $chart-min-height;

$time-series-chart-min-width: 180px;
$time-series-chart-min-height: $chart-min-height;

@mixin chart-overlay-content {
    z-index: 1;
    pointer-events: none;
}
@mixin chart-overlay-content-hover {
    z-index: 0;
}
