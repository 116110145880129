.graph-data-header {
    color: #666666;
    font-size: 1.8em;
    height: 60px;
    line-height: 60px;
    margin: 0;
    box-sizing: border-box;
    padding-left: 65px;
}

.graph-data-content {
    overflow-y: auto;
    height: calc(100% - 70px);
    padding: 0 25px;
}

