// Copyright 2015 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

// Gray scale


$dark-gray1: $gray-100;
$dark-gray2: $gray-200;
$dark-gray3: $gray-300;
$dark-gray4: $gray-400;
$dark-gray5: $gray-500;

$gray1: $gray-500;
$gray2: $gray-600;
$gray3: $gray-700;
$gray4: $gray-800;
$gray5: $gray-900;

// Since we are overriding the $light-gray1 some behaviors like disabled radio control is not shown properly
// So backing up the blueprint variable for using it to set the default control on the indicators
$blueprint-light-gray1: $light-gray1;
$light-gray1: $gray-100;
$light-gray2: lighten($light-gray1, 1%);
$light-gray3: lighten($light-gray1, 2%);
$light-gray4: lighten($light-gray1, 3%);
$light-gray5: lighten($light-gray1, 4%);

// Core colors

$blue1: $blue;
$blue2: lighten($blue1, 1%);
$blue3: lighten($blue1, 2%);
$blue4: lighten($blue1, 3%);
$blue5: lighten($blue1, 4%);

$green1: $green;
$green2: lighten($green1, 1%);
$green3: lighten($green1, 2%);
$green4: lighten($green1, 3%);
$green5: lighten($green1, 4%);

$orange1: $orange;
$orange2: lighten($orange1, 1%);
$orange3: lighten($orange1, 2%);
$orange4: lighten($orange1, 3%);
$orange5: lighten($orange1, 4%);

$red1: $red;
$red2: lighten($red1, 1%);
$red3: lighten($red1, 2%);
$red4: lighten($red1, 3%);
$red5: lighten($red1, 4%);

// Extended colors

$vermilion1: #9e2b0e !default;
$vermilion2: #b83211 !default;
$vermilion3: #d13913 !default;
$vermilion4: #eb532d !default;
$vermilion5: #ff6e4a !default;

$rose1: #a82255 !default;
$rose2: #c22762 !default;
$rose3: #db2c6f !default;
$rose4: #f5498b !default;
$rose5: #ff66a1 !default;

$violet1: #5c255c !default;
$violet2: #752f75 !default;
$violet3: #8f398f !default;
$violet4: #a854a8 !default;
$violet5: #c274c2 !default;

$indigo1: #5642a6 !default;
$indigo2: #634dbf !default;
$indigo3: #7157d9 !default;
$indigo4: #9179f2 !default;
$indigo5: #ad99ff !default;

$cobalt1: #1f4b99 !default;
$cobalt2: #2458b3 !default;
$cobalt3: #2965cc !default;
$cobalt4: #4580e6 !default;
$cobalt5: #669eff !default;

$turquoise1: #008075 !default;
$turquoise2: #00998c !default;
$turquoise3: #00b3a4 !default;
$turquoise4: #14ccbd !default;
$turquoise5: #2ee6d6 !default;

$forest1: #1d7324 !default;
$forest2: #238c2c !default;
$forest3: #29a634 !default;
$forest4: #43bf4d !default;
$forest5: #62d96b !default;

$lime1: #728c23 !default;
$lime2: #87a629 !default;
$lime3: #9bbf30 !default;
$lime4: #b6d94c !default;
$lime5: #d1f26d !default;

$gold1: #a67908 !default;
$gold2: #bf8c0a !default;
$gold3: #d99e0b !default;
$gold4: #f2b824 !default;
$gold5: #ffc940 !default;

$sepia1: #63411e !default;
$sepia2: #7d5125 !default;
$sepia3: #96622d !default;
$sepia4: #b07b46 !default;
$sepia5: #c99765 !default;

$pt-font-size: 14px;//$font-size-base;
$pt-font-size-large: 18px;//$font-size-lg;
$pt-font-size-small: 12px; //$font-size-sm;
$pt-text-color: $body-color;
$pt-text-color-muted: $text-muted;
$pt-heading-color: $pt-text-color;
$table-border-color: $border-color  ;
$dark-table-border-color: $table-border-color;

@import "~@blueprintjs/core/src/blueprint";

.#{$ns}-button .#{$ns}-icon, .#{$ns}-button .#{$ns}-icon-standard, .#{$ns}-button .#{$ns}-icon-large, .#{$ns}-menu-item > .#{$ns}-icon, .#{$ns}-menu-item {
    color: inherit;
}

.#{$ns}-large .#{$ns}-menu-item {
    padding: 9px 7px;
}

// Setting the popups to have a higher z-index for when the right detail panel gets to a z-index of 115.
body:has(.minimize-details-panel-button) {
    .#{$ns}-popover, .#{$ns}-transition-container, .#{$ns}-overlay, .#{$ns}-overlay-backdrop, .#{$ns}-overlay-content, .#{$ns}-popover2 {
        z-index: 120 !important;
    }
}
