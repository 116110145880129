@import "../../../../utils/themes/common/variables";

.resizable-details-panel {
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

html[theme=light] .incident-brief-blade-container .blade-container-content {
  background-color: $gray-200;
}

html[theme=dark] .incident-brief-blade-container .blade-container-content {
  background-color: $gray-700;
}

.btn-dotted {
  border: 1px dashed grey !important;
  box-shadow: unset !important;

  .bp3-button-text {
    font-size: 14px;
  }

  &:hover {
    .bp3-icon-plus {
      transition: transform 0.2s;
      transform: scale(1.1);
    }
  }

  .bp3-icon-plus {
    border: 2px solid grey;
    border-radius: 50%;
  }
}


.custom-property {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5em 0;
  &:first-child {
    padding-top: 20px;
  }
  &-wrapper {
    display: flex;
    flex: 1;
  }
  &-keys {
    font-weight: bold;
    overflow: hidden;
    display: inline-block;
    padding-right: 10px;
  }
  &-values {
    display: inline-block;
    flex: 2;
  }
  // &-icons {
  //   min-width: 70px;
  // }
  &-title {
    white-space: break-spaces;
  }
  &-setCProp-modal {
    .tir-ui-form {
      min-width: 330px;
      & .bp3-form-group {
        width: 260px;
        max-width: 260px;
      }
    }
    & > div {
      margin-top: 2em;
    }
  }
}
