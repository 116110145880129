.condition-block {
    display: grid;
    grid-template-columns: min-content auto min-content;
    grid-template-rows: auto;
    > * {
        margin: 0 0 0.3rem 0.2rem;
    }
    > .condition-block {
        grid-column-start: 2;
        grid-column-end: 4;
    }
    .block-type-container {
        grid-row-start: 1;
        display: flex;
        margin-left: 0;
        position: relative;
        .block-type {
            display: flex;
            flex-direction: column;
            border-radius: .5rem;
            background-color: #cfc1f5;
            color: #222;
            .content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
            .label {
                min-width: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                writing-mode: vertical-rl;
                transform: rotate(180deg);
                flex-grow: 1;
                padding-bottom: 10px;
            }
            .icon {
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
        &.can-denest .block-type .label {
            padding-bottom: 20px;
        }
        .de-nest-block-btn {
            border-radius: .5rem .5rem 0 0;
            position: absolute;
            left: -2px;
            top: -5px;
            z-index: 1;
            opacity: 0.5;
        }
        &:hover .de-nest-block-btn {
            opacity: 1;
        }
    }
    &.read-only .block-type .label {
        padding-bottom: 0;
    }
    .controls {
        white-space: nowrap;
        text-align: right;
    }
    .clickable {
        cursor: pointer;
    }
}
.condition-block-type-change-popup .contents {
    padding: 10px;
    min-width: 125px;
    .operation-menu {
        margin-bottom: 10px;
        border-bottom: 1px solid #77777777;
    }
    .negation-checkbox {
        margin-bottom: 0;
    }
}