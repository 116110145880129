@import 'variables.scss';

.navigator-page-dialog {
  width: 650px !important;
  /*width: 1100px !important;*/
  /*height: 900px !important;*/
}

.react-filter-box { padding: 0}

.CodeMirror-hint:has(.cm-hide) {
  display: none !important;
}

.CodeMirror-scroll {
  overflow: visible!important;
}

.CodeMirror-vscrollbar {
  overflow: hidden!important;
}

.CodeMirror-hscrollbar {
  overflow: hidden!important;
}

html[theme=dark] {
  .react-filter-box {
    background-color: #161A25;
    border: 1px solid #161A25;
  }
  .CodeMirror {
    background: #161A25;
  }
  .CodeMirror-hints {
    background: #30404d;
    border: 1px solid #30404d;
  }
  .CodeMirror-cursor {
    border-left: 1px solid white;
  }
}
