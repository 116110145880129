.icon-video-dialog {
  width: 680px !important;
  height: 450px !important;
}

.ribbon {
  cursor: pointer;
  width: 40px;
  height: 60px;
  background-color: green;
  position: absolute;
  right: 50px;
  top: -70px;
  animation-name: slideDown;
  animation-duration: 60s;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: -10px;
    border-left: 20px solid green;
    border-right: 20px solid green;
    border-bottom: 10px solid transparent;
  }

  .bp3-icon-learning {
    position: absolute;
    top: 22px;
    left: 13px;
    filter: brightness(5);
    &:hover {
      transform: scale(1.1);
    }
  }
}

@keyframes slideDown {
  0% { top: -70px }
  15% { top: -70px; }
  16% { top: 0px; }
  99% {top: 0px; }
  100% {top: -70px; }
}

.page-title-holder .simple-tooltip-target .simple-tooltip .simple-tooltip-content {
  left: -100px;
}
