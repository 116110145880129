.schedule-runbook-modal.schedule-runbook-modal {
    .bp3-dialog-step {
        cursor: pointer;
    }
    
    .overflow-scroll {
        overflow-y: auto;
        padding: 3px;
    }

    table {
        width: 100%;
        tbody {
            tr {
                td {
                    .bp3-popover-target {
                        min-width: 100%;
                        * {
                            min-width: 100%;
                        }
                    }
                }
                &:nth-child(2) {
                    td {
                        &:first-child {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}

.schedule-runbook-date-picker {
    &#{&} {
        .DayPicker {
            min-width: 230px;
        }
        .DayPicker-Month {
            width: 100%;
            margin: 0;
        }
    }
}