.cloudim-detail-blade{
    .details-panel-content {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        padding-right: 10px;
    }

    .cloudim-summary {
        flex: 0 1 auto;
    }

    .cloudim-topology {
        flex: 1 1 auto;
        min-height: 400px;
    }
}

html[theme=dark] .cloudim-detail-blade{
    >div:first-child, .bp3-button:not([class*="bp3-intent-"]){
        background: #445e87;
    }

    .details-panel-content {
        background-color: #0a112e;
    }

    .card, .card:hover{
        background-color: #0a112e;
        box-shadow: none;
    }
}
