@import 'variables.scss';

.edit-navigator-dialog {
    &.main-content {
    width: 1250px!important;
    min-width: 1250px!important;
        height: 950px;
    .bp3-dialog-header {
      z-index: 100;
    }
    }

    &.panel-content {
    max-width: 900px;
        height: 900px;
        display: flex;
        flex-direction: column;
    }
}

.react-grid-item {
  overflow: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
}

.widget-headings {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  cursor: move;
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.npm-plus-column-container { 
  .left-column {
      background-color: #FAFAFA;
      padding: 0 0.5rem 0.5rem;
      border-radius: 10px;
      margin: 10px 20px 10px 10px;
      overflow: visible;
      align-self: flex-start;
      position: -webkit-sticky;
      position: sticky;
      top: 40px;
  }
  .widget-area {
    padding-top: 10px;
    padding-bottom: 50px;
    &.entity-list-hidden {
      margin-left: 20px;
    }
  }
}

html[theme="dark"] {
  .npm-plus-column-container { 
    .left-column {
      background-color: transparent;
    }
  }
}
