.d3-geomap-loader.d3-geomap-loader.d3-geomap-loader {
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}

.d3-geomap {
    cursor: pointer;
    height: 100%;
    width: 100%;
    position: relative;
}

.d3-geomap text {
    fill: #343a40;
}

.d3-geomap .dragging {
    cursor: move;
}

.d3-geomap .background {
    fill: #d8e2ed;
}

.d3-geomap .unit {
    fill: white;
    stroke: white;
    stroke-width: 1px;
}

.d3-geomap .pins line {
    stroke: #343a40;
}

html[theme=dark] .d3-geomap{
    .background {
        fill: #0a112e;
    }

    .unit {
        fill: #2f406a;
        stroke: #2f406a;
        stroke-width: 1px;
    }

    .pins line {
        stroke: white;
    }

    .pins text {
        fill: white;
    }

    .bg-light{
        background: #27385d !important;
    }

    .bp3-button.bp3-intent-primary{
        background-color: #3496cb;
        border: 2px solid #3496cb;
    }

    .bp3-button.bp3-intent-primary:hover {
        background-color: #27385d !important;
        border-color: #3496cb;
    }
}

.d3-geomap .legend-bg {
    fill: #fff;
    fill-opacity: .8;
}

.d3-geomap .legend-bar {
    stroke: #343a40;
    stroke-width: 1px;
}

.d3-geomap .pins>g {
    cursor: pointer;
    dominant-baseline: middle;
}

.d3-geomap .pins circle {
    stroke: none;
}

.d3-geomap .tooltip.tooltip {
    position: absolute;
    background-color: white;
    border: 1px solid #999999;
    border-radius: 0.5rem;
    padding: 10px;
    opacity: 1.0;
}

.d3-geomap .tooltip.hidden {
    display: none;
}

.d3-geomap .tooltip .displayName {
    font-weight: bold;
    font-size: larger;
}

.geoLabel {
    font-weight: bold;
}

.d3-geomap .subMetric {
    padding-left: 10px;
}

.d3-geomap .mapControls,
.d3-geomap .mapLegend {
    position: absolute;
    left: 10px;
    background-color: white;
    padding: 15px;
    border-radius: 0.5rem;
}

.d3-geomap .mapControls {
    top: 10px;
    padding: 5px;
}

.d3-geomap .filterResults {
    padding-top: 5px;
    text-align: right;
    height: 23px;
    font-size: 12px;
    display: none;
}

.d3-geomap .mapLegend {
    bottom: 10px;
    padding-bottom: 5px;
}

.d3-geomap .dataSelect {
    position: absolute;
    top: 10px;
    right: 10px;
}

.pins .AWS circle {
    fill: #FF9900;
}

.pins .AZURE circle {
    fill: #00B5E8;
}

.pins .ONPREM circle {
    fill: #008000;
}

#showAws+span::after,
#showAzure+span::after,
#showOnPrem+span::after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: #FF9900;
    display: block;
    border-radius: 5px;
    position: relative;
    left: 25px;
    top: -13px;
}

#showAws+span::after {
    background-color: #FF9900;
}

#showAzure+span::after {
    background-color: #00B5E8;
}

#showOnPrem+span::after {
    background-color: #008000;
}

#showAws+span,
#showAzure+span,
#showOnPrem+span {
    margin-right: 23px;
}

.hideAzure .AZURE,
.hideAws .AWS,
.hideOnPrem .ONPREM {
    display: none;
}

.d3-geomap .legendText,
.d3-geomap .legendTextBackground {
    text-anchor: start;
    color: white;
    font-size: 10px;
    fill: white;
    font-weight: bold;
}

// .d3-geomap .legendTextBackground{
// stroke: white;
// color: white;
// stroke-width: 0.6em;
// }

.d3-geomap .legendMarker {
    fill: white;
}

.d3-geomap .zoomButtonHolder {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px 8px;
    border-radius: 0.5rem;
}

.d3-geomap .zoomButtons {
    width: 18px;
    height: 42px;
}

.d3-geomap .zoomButton {
    stroke: #343a40;
    ;
    stroke-width: 2px;
    fill: transparent;
    stroke-linecap: square;
    cursor: pointer;
}

.bp3-dark .d3-geomap .zoomButton {
    stroke: #f5f8fa;
}

.d3-geomap.d3-geomap .zoomButton:hover {
    stroke: #6c757d;
}