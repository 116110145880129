.sortable {
    .sort-content {
        .drag-handle {
            cursor: grab;
        }
        &.dragging {
            opacity: 0.3;
        }
    }
    &.vertical {
        .sort-content {
            &.dragged-over {
                margin-top: 15px;
            }
        }
        .sort-content.drop-in-end {
            display: none;
            min-width: 15px;
            min-height: 15px;
        }
        &.drag-in-progress .sort-content.drop-in-end {
            display: block;
        }
    }
    &.horizontal {
        .sort-content {
            display: inline-block;
            &.dragged-over {
                margin-left: 15px;
            }
        }
        .sort-content.drop-in-end {
            display: none;
            min-width: 15px;
            min-height: 15px;
        }
        &.drag-in-progress .sort-content.drop-in-end {
            display: inline-block;
        }
    }
    &.drag-anywhere {
        .sort-content {
            cursor: grab
        }
        .sort-content:active {
            cursor: grabbing;
        }
    }
    &.drag-handles-only {
        .sort-content .drag-handle {
            cursor: grab;
        }
        .sort-content:active .drag-handle {
            cursor: grabbing;
        }
    }
}