.status-led {
    display: inline-flex;
    button {
        min-height: auto!important;
        padding: 1px !important;
    }
}
.status-led .vertical-align-item-center {
    vertical-align: middle !important;
}
.status-icon:hover  {
    background: none !important;
}