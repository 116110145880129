.bp3-dialog.modal-container:has(.bp3-dialog-body .timeline-dialog) {
  min-width: 1100px;
  width: 85% !important;
  min-height: 500px;
  max-height: 90vh;
  overflow-y: auto;
}

.bp3-overlay:has(.bp3-dialog-container .runbook-path-traversal-node-info-dialog) {
  z-index: 110 !important;
}
