.decision-node-editor {
    .add-output-control {
        border-style: dashed !important;
        border-width: 2px !important;
        background-color: transparent !important;
        box-shadow: none !important;
    }
    .sort-content.dragged-over::before {
        content: "";
        display: block;
        border: 2px dashed #77777777;
        background-color: #77777722;
        height: 40px;
        border-radius: 5px;
        margin-bottom: 25px;
    }
    .top-toolbar,
    .add-output-control,
    .default-output {
        transition: opacity 0.5s!important;
        opacity: 1;
    }
    &.reorder-in-progress {
        .top-toolbar,
        .add-output-control,
        .default-output {
            opacity: 0;
        }
    }
    button:disabled {
        opacity: 0.4;
    }
    .output-block-title {
        flex: auto;
        .bp3-input-group {
            flex: auto;
        }
        .duplicate-label {
            input {
                border: 1px solid #dc3545;
            }
        }
        .bp3-popover2-target {
            flex: auto;
        }
    }
}
html[theme=light] .decision-node-editor{
    .decision-node-output {
        background-color: #FFF;
    }
}