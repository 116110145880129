@import "../../../../utils/themes/common/variables";

.input-width input {
  width: 360px!important;
}

html[theme=light] .ac-suggest-row:hover {
  background-color: rgba(52, 58, 64, 0.3);
}

html[theme=dark] .ac-suggest-row:hover {
  background-color: rgba(233, 236, 239, 0.3);
}

html[theme=light] .ac-suggest-row.selected {
  background-color: #137cbd;
}

html[theme=dark] .ac-suggest-row.selected {
  background-color: #137cbd;
}

html[theme=dark] .ac-suggest-list {
  background-color: rgb(36, 40, 51);
}

html[theme=light] .bp3-input.vrp-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}

html[theme=dark] .bp3-input.vrp-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}

.fd-trigger-list-view {
  .row-sub-component-container {
      padding: 0 !important;
      background-color: #FFF;
  }
  .row-expanded {
      background-color: #f1f1f3 !important;
  }
  > .tir-ui-table-container > table {
    table-layout: fixed !important;
  }
}

html[theme=dark] {
  .fd-trigger-list-view {
      .row-sub-component-container {
          background-color: #262b38;
      }
      .row-expanded {
          background-color: #151a25 !important;
      }
  }
}

html[theme=light] .fd-runbook-output-tab-runbook-bg {
  background-color: #e9ecef;
}

html[theme=dark] .fd-runbook-output-tab-runbook-bg {
  background-color: #242833;
}

.view-runbook-input-dialog {
  min-width: 670px !important;
  width: 670px !important;
  max-width: 100vw;
  max-height: 90dvh;
  table {
    width: 100%;
    span {
      line-height: 1.5;
    }
  }
  // This breaks the autocompletes, they then render over the text field
  //.basic-dialog-load-facade {
  //  overflow-y: auto !important;
  //}
  .date-time-selector {
    .bp3-input-group {
      .bp3-input {
        padding-left: 30px !important;
      }
    }
  }
}

.view-runbook-nodes-traversed-dialog {
  width: 72vw !important;
  max-height: 93vh !important;
}
