@import "../../../../utils/themes/common/variables";
html .tabbed-sub-pages {
    height: 100%;
    .bp3-tab-list {
        padding: 0 1rem;
        border-bottom: 1px solid;
        .bp3-tab-indicator {
            margin: 0 10px;
            border-radius: 3px 3px 0 0;
        }
        .bp3-tab {
            padding: 0.8rem 0.8rem 0.5rem 0.8rem;
            font-size: 0.9rem;
            text-transform: uppercase;
            color: $secondary;
            font-weight: 500;
            transition: color $transition-speed-1;
            .status-led {
                vertical-align: top;
                position: relative;
                top: 5px;
                left: 5px;
            }
            .count-in-tab {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                font-size: 0.6rem;
            }
        }
    }
    .bp3-tab-panel {
        flex-grow: 1;
        margin-top: 0;
    }
}

  .copy-view-button{
    background-color: #d5f1f7 !important;
    }

html[theme=light] .tabbed-sub-pages .bp3-tab-list {
    border-bottom-color: $gray-300;
    .bp3-tab-indicator {
        background-color: $black;
    }
    .bp3-tab[aria-selected="true"], .bp3-tab:hover {
        color: $black;
    }
}
html[theme=dark] .tabbed-sub-pages .bp3-tab-list {
    border-bottom-color: $gray-800;
    .bp3-tab-indicator {
        background-color: $white;
    }
    .bp3-tab[aria-selected="true"], .bp3-tab:hover {
        color: $white;
    }
}
