td.fixed-textarea textarea {
    resize: none;
}

html[theme=dark]  {
    // Fix dark theme problems for textarea and suggestions
    // TODO: Fix for the entire project
    .fixed-textarea textarea {
        background-color: rgb(22, 26, 37);
    } 
    div.rta__entity {
        background-color: rgb(22, 26, 37);
        &.rta__entity--selected {
            background-color: #0366d6;
        }
    }
}

.textarea-hack.textarea-group {
    align-items: center;
    gap: 8px;
    display: flex;

    .fill {
        flex: 1 1 auto;
        width: 100%;
    }
    .textarea-group-left {
        flex: 0 0 115px;
    }

    .bp3-input {
        padding-left: 10px;
        height: 30px;
        padding-bottom: 4px;
        padding-top: 7px;
    }

    textarea {
        white-space: nowrap;
    }
}

.float-list {
    z-index: 2;
}

.grid-alignment {
    display: grid;
    grid-template-columns: 2fr 1fr 0.8fr;
    grid-gap: 8px;
}

.http-node-additional-headers {
    .add-additional-header-control {
        border-style: dashed;
        border-width: 2px;
        background-color: transparent;
        box-shadow: none;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 0.5em 0 0.5em 0;
        justify-content: center;
        align-items: center;
        max-width: calc(100% - 26px);
        &.disabled {
            opacity: 0.4;
            pointer-events: none;
        }
    }

    .additional-header-row-insert-variable {
        display: flex;
        margin-right: 26px;
        margin-bottom: 5px;
        .bp3-form-group {
            margin-bottom: 0;
        }
    }

    .additional-header-row {
        margin-bottom: 15px;
        margin-top: 15px;
        &:first-child {
            margin-top: 0;
            position: relative;
            .additional-header-row-insert-variable {
                position: absolute;
                bottom: 30px;
                right: 0;
            }
        }
    }

    .additional-header-row-inputs {
        display: flex;
        .bp3-form-group {
            margin-bottom: 0;
        }
    }

    .additional-header-input {
        flex: 1;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }

    .additional-header-input-error {
        input {
            border: 1px solid #E04B4B;
        }
    }

    .remove-additional-header {
        width: 16px;
        align-self: flex-end;
        margin-bottom: 7px;
    }
}