.integration-library__modal-links {
  background: transparent;
  list-style: "none";
  margin-left: 0;
  padding-left: 0;

  li.list-group-item {
    background-color: transparent !important;
  }
}

.integration-library__modal-referenced-resources {
  max-height: 250px;
  overflow-y: auto;
}

html[theme=dark] li.list-group-item {
  background-color: transparent;
}
