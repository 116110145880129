.modal-container:has(.bp3-dialog-body .custom-property-setCProp-modal) {
  width: 500px;
}

a.disabledLink {
  color: lightgrey !important;
  cursor: not-allowed !important;

  &:hover {
    text-decoration: none !important;
  }
}

html[theme=dark] a.disabledLink {
  color: grey !important;
}
