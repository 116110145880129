@import "../../../utils/themes/common/variables";

.scrollable-errors {
  color: $critical-color;
  font-size: 14px;
  font-weight: 400;
  max-height: 107px;
  overflow-y: auto;
  div {
    margin-bottom: 5px;
  }
}

.scrollable-warnings {
  font-size: 14px;
  font-weight: 400;
  max-height: 107px;
  overflow-y: auto;
  div {
    margin-bottom: 5px;
  }
}
