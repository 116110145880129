@import 'variables.scss';

html[theme=light] .ds-table-column {
  background-color: $light-gray2;
}

html[theme=dark] .ds-table-column {
  background-color: $dark-gray4;
}

#bp3-tab-title_undefined_tab-aternity {
  text-transform: none;
}

.aternity-config label {
  min-width: 150px;
}
