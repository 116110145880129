@import 'variables.scss';

.authentication-profile-modal {
	&.main-content {
		height: 640px;

		.bp3-multistep-dialog-panels {
			height: 100%;
		}
		.panel-content {
			display: flex;
			flex-direction: column;
			overflow-y: auto;

			textarea {
				resize: none;
			}

			.bp3-dialog-body {
				padding-left: 4px;
				padding-right: 4px;
				max-height: 500px;
				overflow-y: auto;
			}
		}
		.bp3-dialog-step-viewed .bp3-dialog-step {
			cursor: pointer;
		}
	}

	.http-success::first-line {
		color: green;
	}
	.http-error::first-line {
		color: red;
	}
}

.add-alluvio-edge-control {
    border-style: solid;
    border-width: 1px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1em 0 0.5em 0;
    justify-content: center;
    align-items: center;
    div.bp3-popover2-arrow:not(.bp3-minimal) {
        // The Edge control panel is a minimal popover
        // This is a workaround to display other popovers arrows since they are all affected by bp3-minimal class
        display: block;
    }
	div.bp3-popover2-content {
		overflow-y: scroll;
		max-height: 400px;
	}
}

.fieldset--with-bg {
  background-color: $light-gray5;
}

.advanced-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 2rem;
  gap: .5rem;
  grid-gap: .5rem;
 
  .bp3-form-group {
    margin-bottom: 0 !important;
    margin-top: 15px !important;
  }
  .simple-tooltip {
    right: -150px;
  }
}

html[theme=dark] {
	.fieldset--with-bg {
		background-color: $dark-gray3;
	}
}
