
.error-page {
  max-width: 650px;
  word-wrap: break-word;
  .error-content {
    margin-top : 10px
  }
  .support {
    white-space: pre-line;
  }
}
