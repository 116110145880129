@import 'variables.scss';

.bp3-dialog.main-page--error-dialog {
    background: white;
    border-top: 6px solid $red2 !important;
    margin-top: -200px; // account for the iframe
    text-align: center;

    .bp3-dialog-header {
        box-shadow: none;
    }
}