@import "../../../../../utils/themes/common/variables";
.criticalityCircle {
  margin: 2px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.criticalityCircle-small {
  height: 30px;
  width: 30px;
}

.criticalityCircle-medium {
  height: 40px;
  width: 40px;
}

.criticalityCircleCritical {
  background: $critical-color;
}

.criticalityCircleDegraded{
  background: $degraded-color;
}

.criticalityNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  font-weight: 500;
  color: white;
  font-size: medium;
}

.criticalityNumber-small {
  font-size: x-small;
}

.criticalityNumber-medium {
  font-size: medium;
}
