.display-loading-in-context {
    position: relative;
    min-width: 100px !important;
    min-height: 100px !important;
    height: 100%;
    &.show-contents {
        height: auto;
    }
    .bp3-overlay-backdrop {
        position: absolute !important;
    }
    .loading-overlay {
      color: white;
    }
}
.data-load-facade.transparent-bg .loading-overlay {
    background: transparent;
}
