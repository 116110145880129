.bp3-button.show-information-icon.warning,
.bp3-button.show-information-icon.warning:hover {
    color: #fcda5d !important;
}

.show-information-icon.bp3-outlined.warning {
    border: 1px solid #fcda5d !important;
}

.runbook-info-dialog {
    width: 650px !important;
}
