.condition {
    display: flex;
    .condition-operation,
    .condition-value {
        margin-left: 0.3rem;
    }
    .display-holder {
        display: inline-flex;
        align-items: center;
        .icon {
            margin-right: 0.1rem;
            margin-top: 0.1rem;
        }
        .dropdown-icon {
            margin-right: -0.1rem;
            margin-top: 0.1rem;
            opacity: 0.5;
        }
    }
    .clickable {
        cursor: pointer;
    }
    .condition-key,
    .condition-operation,
    .condition-value {
        border-radius: .8rem;
        color: #222;
        display: inline-flex;
        align-items: center;
    }
    .add-padding {
        padding: 0.2rem 0.6rem;
    }
    .condition-key {
        background-color: #f9e4a7;
        overflow: hidden;
        .category {
            background-color: #f4c7a9;
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .condition-operation {
        background-color: #caefb1;
    }
    .condition-value {
        background-color: #c6e3fe;
    }
}