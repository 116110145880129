html[theme=light],
html[theme=dark] {
  section.MissingRole-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    p {
      font-size: 24px;
      font-weight: 600;
    }
  }
}
