.bp3-overlay:has(.bp3-dialog-container .confirmation-modal) {
  z-index: 99999; // overwrite bp3-overlay z-index
}

.confirmation-modal {
  &#{&}#{&} {
    z-index: 99999; // it should always be in front of anything else
  }
  
  .bp3-alert-contents {
    margin: 0 auto;
  }
  
  .message {
    white-space: pre-line;
  }
}
