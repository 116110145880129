.sticky-table-header table thead tr th {
  position: sticky;
  top: -20px;
  z-index: 9;
}

html[theme=light] .sticky-table-header table th {
  background: #e1e1e1;
}
html[theme=dark] .sticky-table-header table th {
  background: #4d4759;
}

button.link-style {
  background: none;
  border: none;
  font-weight: 300;
  padding: 0;
  text-align: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

html[theme=light] .link-style {
  color: #106ba3;
}
html[theme=dark] .link-style {
  color: #4da3ff;
}
