@import "~@blueprintjs/core/src/blueprint";

.time-period-more-view, .time-period-more-view-popover-content {
  display: inline-flex;
  .time-period-more-option-button {
    width: 40px;
    height: 27px;
    letter-spacing: 1.6px;
    .#{$ns}-button-text {
      padding-bottom: 8px;
    }
  }
  
  .time-period-more-button-selected {
    border-radius: 3px !important;
  }
  
  .time-period-more-option-label {
    height: 17px;
    margin: 5px;
    margin-left: 10px;
  }
  
  .time-period-horizontal-line {
    border-bottom: 1px solid rgba(16, 22, 26, 0.15);
    margin-inline: 5px;
  }
  
  .time-period-select-date-range {
    display: inline-block;
    .time-period-date-range-picker {
      background-color: inherit;
      .DayPicker, .DayPicker-Months, .DayPicker-Month {
        width: 100%;
      }
      .DayPicker-Month {
        margin: 0;
      }
      .#{$ns}-timepicker:first-of-type {
        margin-right: 5px;
      }
      .#{$ns}-timepicker-arrow-row {
        text-align: left;
      }
    }
  }
  .#{$ns}-datepicker {
    .DayPicker-Day.DayPicker-Day--selected {
      border-radius: 15px;
    }
    .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}


html[theme="dark"]
{
  .time-period-more-view-popover{
    .#{$ns}-popover-arrow-fill {
      fill: #30404d !important;
    }
  }
} 
