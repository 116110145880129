@import "../../../utils/themes/common/variables";

.multi-select-input {
  .#{$ns}-menu {
    font-size: 14px;
    font-weight: 400;
  }
  .draggable-tag {
    cursor: move;
    user-select: none;
    padding-left: 0 !important;
    .bp3-icon {
      margin-right: 0;
    }
    &.dragging {
      opacity: 0.4;
    }
    &.dragged-over {
      margin-left: 15px;
    }
  }
}