/*.#{$ns}-dialog {
  width: auto
}*/

.#{$ns}-dark {
  .#{$ns}-dialog {
    background-color: lighten($body-bg, 5%);
    box-shadow: $pt-dark-dialog-box-shadow;
    color: $pt-dark-text-color;
    .#{$ns}-dialog-header {
      background-color: #393b40;
    }
  }

  .#{$ns}-control {
    &.#{$ns}-radio {
      .#{$ns}-control-indicator {
        border-radius: 50%;
      }

      input:checked ~ .#{$ns}-control-indicator::before {
        background-image: radial-gradient(#FFFFFF, #FFFFFF 28%, transparent 32%);
      }

      input:checked:disabled ~ .#{$ns}-control-indicator::before {
        opacity: 0.5;
      }

      input:focus ~ .#{$ns}-control-indicator {
        -moz-outline-radius: $control-indicator-size;
      }
    }
  }
  .#{$ns}-input {
    color: #f5f8fa;
  }

  .#{$ns}-button,
  .#{$ns}-button:hover {
    color: #FFFFFF;
    box-shadow: none;
  }

  .#{$ns}-button {
    &.#{$ns}-outlined {
      border: 1px solid #6464fd !important;
      // &:hover {
      //   border: 1px rgba(52, 58, 64, 0.3) !important;
      // }
    }
  }

  .#{$ns}-callout {
    &.#{$ns}-intent-primary {
      background-color: rgba(19, 124, 189, 0.25);
      > .#{$ns}-icon:first-child {
        color: #48aff0
      }
    }
  }

  .#{$ns}-card {
    background-color: $card-bg;
  }

  .#{$ns}-button-group {
    .#{$ns}-button:not(.#{$ns}-disabled) {
      &:hover {
        color: #00a5ff !important;
      }
    }

    &:not(.#{$ns}-minimal) {
      & .#{$ns}-button {
        &:not(:last-of-type) {
          border-right: 2px solid #242833 !important;
        }
  
        &:not(:first-of-type) {
          border-left: 2px solid #242833 !important;
        }
      }
    }
  }

  .led-holder {
    background-color: #6464fd;
  }

  .main-navbar a:hover,
  .sidebar a:hover {
    color: #FFF !important;
  }
}
