@import "utils/themes/common/variables";

html[theme=dark] {
    .netimNode .icon {
        background-color: $dark-gray4;
    }

    .netimNode .icon svg path, .netimContextMenu .icon svg path {
        // Colors were based off what was seen on other AWS icon's colors, keeping it consistent with NetIM data
        stroke: #FFFFFF;
        fill: #FFFFFF;
        stroke-width: 0;
    }
}

html[theme=light] {
    .netimNode .icon {
        background-color: $light-gray1;
    }

    .netimNode .icon svg path, .netimContextMenu .icon svg path {
        // Colors were based off what was seen on other AWS icon's colors, keeping it consistent with NetIM data
        stroke: #242F3E;
        fill: #242F3E;
        stroke-width: 0;
    }
}

.netimContextMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.netimContextMenu .icon svg {
    width: 30px;
    height: 30px;
}

.netimContextMenu .details {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    white-space: pre-wrap;
}

.netimNode {
    display: flex;
    justify-content: center;
    // Margin used to hide the imperfections of the edges
    margin: -5px;
}

.netimNode .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 20px;
}

.netimNode .icon svg {
    width: 25px;
    height: 25px;
}

.netimNode .labelContainer {
    position: absolute;
    top: 45px;
}

.netimNode .labelContainer .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 170px;
}

.netimNode .labelContainer .details span {
    display: -webkit-box;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.netimNode.showText .labelContainer .details span {
    overflow: visible;
}
