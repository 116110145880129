.entity-list {
    .entity-list-header {
        background: #fafafa;
        border-bottom: 2px solid lightgray;
    }
    .entity-list-items {
        .entity-list-item {
            padding-bottom: 4px;
            margin-bottom: 4px;
            font-size: 13px;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 8px;
            }
        }
    }
    .entity-list-category {
        border-radius: 5px;
        border: 2px solid #e4e4e4;
        padding: 15px 15px 8px 15px;
        background-color: #fff;
        font-size: 14px;
        .pin-entity {
            width: 17px;
            height: 17px;
            padding: 0;
            vertical-align: middle;
            line-height: 9px;
            margin-left: auto;
            border: 1px solid #343a40;
            background: transparent;
            border-radius: 50%;
            svg {
                width: 14px;
                height: 14px;
            }
        }
        .entity-list-category-name {
            display: flex;
        }
    }
}

html[theme="dark"] {
    .entity-list {
        border-radius: 10px;
    }
    .entity-list-header {
        border-bottom: 1px solid #0c0f15;
        background: transparent;
    }
    .entity-list-category {
        border: 1px solid #0c0f15;
        background: transparent;
    }
}