.metric-mini-card .chart-tile {
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: hidden;
}

.metric-mini-card {
    .card-with-title-header {
        text-align: center;
    }
    .card-value {
        text-align: center;
    }
}
