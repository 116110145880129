html .card-with-title-and-status {
    margin-right: 1rem;
    margin-bottom: 1rem;
    &.status-border {
        border-width: 0 0 0 4px;
    }
    .card-with-title-header {
      .card-title-holder {
        height: 36.8px;
      }
      .card-icon {
          opacity: 1;
          padding-right: 10px;
      }
    }
    .card-content-holder {
        padding-top: 15px;
        &:has(div.chart-tile.sparkline-tile) {
          padding-top: 10px;
        }
    }
    &.card-size-m {
        width: 385px;
    }
    &.card-size-l {
        width: 685px;
    }
    &.card-size-s {
        width: 180px;
        .footer-text {
            font-size: 0.7rem;
        }
    }
    &.card-size-flex {
        flex: 1;
    }
    &.card-height-l {
        height: 405px;
    }
    &.card-height-m {
        height: 180px;
    }
    &.card-height-s {
        height: 100px;
        .card-content-holder {
            padding-top: 8px;
        }
    }
}

html[theme=light] .card.card-with-title-and-status.metric-mini-card {
  background-color: white;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.04) !important;
}

// Hollow Card (dashed border, no bg, etc)
.card.card-with-title-and-status.metric-mini-card.hollow {
  background: none !important;
  border: 1px dashed grey;
  .card-body:hover {
    .bp3-icon-plus {
      transition: transform 0.2s;
      transform: scale(1.2);
    }
  }

  .card-content-holder {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: unset !important;
    padding-top: 50px !important;  
    .bp3-icon-plus {
      border: 2px solid grey;
      border-radius: 50%;
      padding: 5px;
    }
    span.display-9 {
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
}
