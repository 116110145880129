@import 'variables.scss';

.v-center-span {
  display: flex;
  align-items: center;
  padding-right: 5px;
  &:not(:first-child) {
    padding-left: 5px;
  }
}
html[theme=light],
html[theme=dark]{
  .do-required-label {
    display: inline-flex;
  }
}

/*


html[theme=light] {
  .small-input-groups {
    display: flex;
    justify-content: space-between;
    ;
    ////display: inline-block;
    //
    //span {
    //  //font-size: 14px;
    //  margin-right: 10px;
    //  //> * {
    //  //  margin-right: 0px;
    //  //}
    //  .#{$ns}-input-group {
    //    display: inline-block;
    //    .#{$ns}-input {
    //      width: 50px;
    //      height: 30px;
    //      text-align: center;
    //    }
    //  }
    //}
  }
}

*/
