
body .capacity-planning-list-card .tir-ui-table-container table.tir-ui-table.bp3-html-table.bp3-html-table-condensed tbody tr td {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
body .capacity-planning-list-card .tir-ui-table-container table.tir-ui-table.bp3-html-table.bp3-html-table-bordered tbody {
  box-shadow: none !important;
  background: transparent !important;
  tr:not(:first-child) {
      border-top: none;
  }
  td:first-child {
    box-shadow: none;
  }
}

.overflow-elipsis-table-cell {
  text-align: left;
  text-overflow: ellipsis;
}

.summary-interface-widget-max-width {
  max-width: 230px;
}

.capacity-planning-table-view .tir-ui-table-container {
  padding-top: 0 !important;
}

.capacity-planning-table-view .comparison-value-container {
  display: inline-block;
  .value {
    min-width: 25px;
    display: inline-block;
    text-align: right;
  }
  .unit {
    min-width: 20px;
    display: inline-block;
    padding-left: 4px;
  }
}

.capacity-planning-list-card {
  height: auto !important;
  min-height: 405px;
  .card-content-holder {
    padding-top: 0 !important;
  }
  .bp3-tag {
    background-color: transparent !important;
    color: #343a40 !important;
    font-weight: 700;
    &.bp3-intent-warning {
      background-color: #edd811 !important;
      color: #343a40 !important;
    }
    &.bp3-intent-danger {
      background-color: #db3737 !important;
      color: #fff !important;
      font-weight: normal;

    }
  }
}

body .capacity-planning-list-card .tir-ui-table-container table.tir-ui-table.bp3-html-table.bp3-html-table-condensed.bp3-html-table-striped tbody tr:nth-child(odd) td {
  background: rgba(0,0,0,0.05) !important;
}

.special-chart-ai {
  color: #000091 !important;
}

.capacity-planning-table-view {
  .tir-ui-table-container.overflow-auto {
    overflow: visible !important;

    .simple-tooltip-target {
      position: relative;
    }
    .simple-tooltip {
      left: 50%;
      width: max-content;
    }
    .simple-tooltip-content.position-absolute {
      position: unset !important;
    }
  }
}
