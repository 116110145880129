@import "../../../utils/themes/common/variables";

.connection-graph-component {
    @media screen and (max-height: 500px) {
        .toolbar {
            top: 30px;
            height: 30px;
            line-height: 30px;
        }
    }
    .react-flow__node {
        /*box-shadow: 2px 2px 5px #00000040;*/
        a {
            font-weight: 700;
            color: #111;
        }
        .dragging {
            z-index: 1000;
        }
    }
    .react-flow__edge {
        cursor: pointer;
        opacity: 0.5;
        &.selected {
            opacity: 1;
        }
    }
    .react-flow__edge-path {
        stroke: #b1b1b7;
        stroke-width: 3;
    }
    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output {
        padding: 0;
        /*border-radius: 7px;*/
        /*border: 1px solid #AAA;*/
        /* remove this */
        width: 70px;
        border: none;
        .contents {
            min-height: 30px;
            display: flex !important;
            align-items: stretch !important;
            justify-content: center;
            &>.icon,
            >.name {
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }
            &>.icon {
                border-radius: 7px 0 0 7px;
                padding-left: 7px;
                padding-right: 7px;
                background: rgba(0, 0, 0, 0.3);
                color: #FFF;
            }
            &>.name {
                padding: 3px;
            }
        }
    }
    .react-flow__node-input .react-flow__handle,
    .react-flow__node-output .react-flow__handle,
    .react-flow__node-default .react-flow__handle {
        /* remove to go back to similar UX to runbook editor */
        opacity: 0;
        background: #b1b1b7;
        width: 10px;
        height: 10px;
        &.target:hover {
            transition: width 0.1s, height 0.1s, left 0.1s, right 0.1s;
            height: 20px;
            width: 20px;
            left: -10px;
        }
    }
}

html[theme=dark] {
    .connection-graph-component {
        background-color: $black;
        .react-flow__node {
          /* added this */
          color: $white;
          /*box-shadow: 2px 2px 5px #000000;*/
        }
    }
}
