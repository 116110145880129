.data-card .card-close-button {
    position: absolute;
    right: 0;
    z-index: 1;
    &.bp3-button {
        border-radius: 0;
    }
}

.data-card {
  &.card-height-l {
      height: 400px;
  }
  &.card-height-m {
      height: 200px;
  }
  &.card-height-s {
      height: 100px;
  }
}

.data-card.hide-shadow {
  box-shadow: none!important;
}

.data-card.hide-border {
  border: none!important;
}
