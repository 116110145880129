@import 'variables.scss';

.tir-custom-property {
	width: 100%;
	padding: 15px;

	.description-field {
		width: 350px;
	}
}
