// // all the imports required to make blueprintjs work
@import "~@tir-ui/react-components/dist/react-components.esm.css";

// Temporary background-color while dark styles get loaded as per user's preference
html[thememode=dark] body {
  background-color: #212529;

  #root {
    visibility: hidden;
  }
}
