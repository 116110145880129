@import "utils/themes/common/variables";

html[theme=dark] {
    .awsNode .icon svg, .awsContextMenu .icon svg {
        background-color: $dark-gray4;
    }

    .awsNode .icon .themeIcon path, .awsContextMenu .icon .themeIcon path {
        // Colors were based off what was seen on other AWS icon's colors
        fill: #FFFFFF;
    }
}

html[theme=light] {
    .awsNode .icon svg, .awsContextMenu .icon svg {
        background-color: $light-gray1;
    }

    .awsNode .icon .themeIcon path, .awsContextMenu .icon .themeIcon path {
        // Colors were based off what was seen on other AWS icon's colors
        fill: #242F3E;
    }
}

.awsContextMenu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.awsContextMenu .icon .themeIcon {
    width: 30px;
    height: 30px;
}

.awsContextMenu .details {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.awsNode {
    display: flex;
    justify-content: center;
    // Margin used to hide the imperfections of the edges
    margin: -5px;
}

.awsNode .icon svg, .awsContextMenu .icon svg {
    border-radius: 20px;
}

.awsNode .labelContainer {
    position: absolute;
    top: 45px;
}

.awsNode .labelContainer .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 170px;
}

.awsNode .labelContainer .details span {
    display: -webkit-box;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.awsNode.showText .labelContainer .details span {
    overflow: visible;
}
