.data-node {
    &.show-border .desc {
        border-left: 1px solid #D9D9E0;
    }

    .title {
        margin-left: 1px;
    }

    .title-text {
        width: 230px;
    }

    .flow-wrap {
        z-index: 32;
    }
}
