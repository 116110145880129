.api-key-auth-params-panel {
    .add-request-header-control {
        border-style: dashed;
        border-width: 2px;
        background-color: transparent;
        box-shadow: none;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 0.5em 0 0.5em 0;
        justify-content: center;
        align-items: center;
        max-width: calc(100% - 26px);
    }

    .request-header-input {
        flex: 1;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }

    .request-header-row {
        display: flex;
        align-items: top;
        margin-bottom: 15px;
        .bp3-form-group {
            margin-bottom: 0;
        }
    }

    .remove-request-header {
        width: 16px;
        padding-top: 5px;
    }
}