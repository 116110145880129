html[theme="light"] section.FF-container,
html[theme="dark"] section.FF-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

section.FF-Fallback {
  p.FF-Fallback-text {
    font-size: 24px;
    font-weight: 600;
  }
}
