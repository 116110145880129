.authentication-parameters-field-wrap {
    position: relative;
    .authentication-parameters-upload-field-wrap {
        position: absolute;
        top: -10px;
        right: 0;

        input[type="file"] {
            display: none;
        }
    }
    br {
        display: none;
    }
    .field-error {
        overflow: visible;
    }
    .field-error:empty {
        display: none;
    }
    .privateKeyClearedHeading {
        margin-bottom: 25px;
        display: flex;
        &>span {
            margin-left: 20px;
        }
        button {
            margin-left: auto;
        }
    }
    textarea.bp3-input[name="client_certificate_in_pem_format"] {
        height: 120px;
    }
    textarea.bp3-input[name="client_private_key"] {
        height: 100px;
    }
    .bp3-popover2-target {
        position: absolute;
        left: 213px;
        top: -1px;
    }
    &.client-private-key {
        .bp3-popover2-target {
            left: 130px;
        }
    }
}