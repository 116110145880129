@import "../../../../utils/themes/common/variables";
.details-panel {
    display: flex;
}
#right-details-pane, #dialog-details-pane {
    display: flex;
    transition: width $transition-speed-1 linear !important;
    position: relative;
    overflow:hidden;
    width: 0;
    &.show {
        width: 400px;
        &.large {
          width: 550px;
        }
        &.x-large {
            width: 1024px;
        }
        &.resizable {
            width: auto;
        }
    }

    &.floating {
        position: relative;
        width: auto;
        left: auto;
        z-index: 10;
        &.show {
            right: 0;
            width: 400px;
            &.large {
            width: 550px;
            }
            &.x-large {
                width: 1024px;
            }
            &.resizable {
                width: auto;
            }
        }
    }
    .loading-overlay {
        position: absolute !important;
    }
    > * {
        flex-grow: 1;
        overflow: auto;
    }
}
html[theme=light] {
    .bp3-html-select.bp3-disabled {
        background-color: rgba(108, 117, 125, 0.3);
        border-radius: 3px;
    }
}
