@import "../../../utils/themes/common/variables";

html {
  &[theme=light],
  &[theme=dark] {
    .#{$ns}-menu {
      border-radius: 0 !important;
    }
    .select-input {
      .#{$ns}-menu {
        font-size: 14px;
        font-weight: 400;
      }

      .#{$ns}-popover-content {
        padding: 5px;

        .with-icon {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .#{$ns}-button.selected-item-display-btn {
        background-image: none;
        &.icon-align-top {
          align-items: flex-start;
        }
      }
    }
  }

}
