.add-output-control {
    border-style: dashed !important;
    border-width: 2px !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.variable-template-dialog {
    min-width: 670px !important;
    width: fit-content !important;
    max-width: 100vw;
    max-height: 90dvh;
  
    .basic-dialog-load-facade {
      overflow-y: auto !important;
    }
}

.resizable-details-panel:has(.set-simple-variables-editor-tabs) {
  flex: 0 0 650px;
}
