* {
    transition: border-color $transition-speed-2, background-color $transition-speed-1;
    font-family: $font-family-sans-serif;
}
html, body, #root {
    width: 100%;
    height: 100vh;
    min-width: 375px;
    visibility: visible;
}
@import './components/navbars';
@import './components/reporting';
@import './components/blueprint_dark_theme';
@import './components/buttons';
@import './components/dialogs';
@import './components/form_controls';
@import './components/table';

.tir-ui-pagination input, .tir-ui-pagination button {
    height: 30px;
}
.text-shadow-sm { text-shadow: 0 0 3px $body-bg; }
.bg-light { background: lighten($body-bg, 5%)!important; }
.color-muted { color: $text-muted; }
.bg-muted { background: darken($body-bg, 1%)!important; }
.text-black { color: $black; }
// .text-secondary { color: $secondary !important; } 
pre {
    color: $pt-text-color;
}

// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
//     background-color: $scrollbar-track-color;
// }
// ::-webkit-scrollbar {
//     width: 6px;
//     height: 6px;
//     background-color: $scrollbar-track-color;
// }
// ::-webkit-scrollbar-thumb {
//     background-color: $scrollbar-thumb-color;
//     border-radius: 4px;
// }

$severity-colors: (
    normal: $normal-color,
    degraded: $degraded-color,
    critical: $critical-color,
    unknown: $unknown-color
);
@each $key, $value in $severity-colors {
    .text-#{$key} { color: $value !important; }
    .bg-#{$key} { background-color: $value !important; }
    .border-#{$key} { border-color: $value !important; }
}
$priority-colors: (
    priority-critical: $critical-priority-color,
    priority-high: $high-priority-color,
    priority-moderate: $moderate-priority-color,
    priority-low: $low-priority-color,
    priority-unknown: $unknown-priority-color
);
$priority-text-colors: (
    priority-critical: #FFF,
    priority-high: #FFF,
    priority-moderate: #000,
    priority-low: #000,
    priority-unknown: #999
);
@each $key, $value in $priority-colors {
    .text-#{$key} { color: $value !important; }
    .#{$ns}-button.bg-#{$key},
    .bg-#{$key} {
        background-color: $value !important;
        color: map-get($priority-text-colors, $key);
        box-shadow: none;
    }
    .border-#{$key} { border-color: $value !important; }
}
body .tir-ui-table-container table.tir-ui-table.#{$ns}-html-table.#{$ns}-html-table-bordered,
body.#{$ns}-dark .tir-ui-table-container table.tir-ui-table.#{$ns}-html-table.#{$ns}-html-table-bordered.#{$ns}-html-table-bordered {
    tbody {
        background: lighten($body-bg, 5%);
        tr:not(:first-child) {
            border-top-color: $body-bg;// #0c0f15;
        }
    }
    &.tir-ui-table-bg-transparent tbody {
        background: none;
    }
    .row-sub-component-container {
      box-shadow: 0 0 10px $box-shadow-color inset;
    }
}
body .tir-ui-table-container table.tir-ui-table {
    .expand-row {
        white-space: nowrap;
    }
    tbody tr:first-child td:first-child {
        box-shadow: none;
    }
    &.#{$ns}-html-table.#{$ns}-html-table-bordered {
        tbody {
            box-shadow: 0 6px 12px $box-shadow-color;
        }
        &.tir-ui-table-bg-transparent tbody {
            box-shadow: none;
        }
    }
}
.shadow {
    box-shadow: 0 6px 12px $box-shadow-color;
}
button.toggleable,.#{$ns}-button.toggleable {
    opacity: 0.6;
    &:hover {
        opacity: 1;
    }
    &.#{$ns}-active,&.active {
        box-shadow: 0 1px 5px #388737!important;
        opacity: 1;
    }
}
.#{$ns}-input{
    &:disabled,
    &.#{$ns}-disabled {
        background: $body-bg;
        box-shadow: 0 0 0 1px inset $box-shadow-color;
    }
}

.#{$ns}-control {
  input:disabled {
    ~ .#{$ns}-control-indicator {
      background-color: rgba($blueprint-light-gray1, 0.5);
    }
  }
}

ul.no-bullets {
    list-style-type: none;
    padding: 0;
}
