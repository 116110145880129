@import "variables";

.tir-ui-form {
  .tir-ui-radio-btn {
    .#{$ns}-label {
      margin-bottom: 10px;
    }
  }
}

//TODO: Move this within .tir-ui-form when all forms start using the common form component
span.field-error {
  color: $red2;
  display: inline-flex;
  max-height: 80px;
  overflow-y: auto;
  padding-top: 2px;
}

