@import 'variables.scss';

.tir-roles-section {
  label {
    &.required::after {
      content: '*';
      color: $red4;
    }
  }
}
