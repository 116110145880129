.widget-editor {
    .widget-editor-header {
        background: #fafafa;
        border-bottom: 2px solid lightgray;
    }
    .widget-editor-items {
        .widget-editor-item {
            padding-bottom: 4px;
            margin-bottom: 4px;
            cursor: pointer;
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 8px;
            }
        }
    }
    .widget-editor-category {
        border-radius: 5px;
        border: 2px solid #e4e4e4;
        padding: 8px 15px;
        background-color: #fff;
    }
}