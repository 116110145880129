.json-viewer-content {
    overflow-y: auto;
    height: calc(100% - 70px);
    max-width: 620px;
}

.json-viewer-content .bp3-tree-node-label {
    user-select: text !important;
    overflow: visible !important;
}

.json-viewer-content .info-warning-error-label .bp3-tree-node-content {
    min-height: 30px;
    height: unset !important;
}

.json-viewer-content .info-warning-error-label .icon-and-label-div {
    white-space: break-spaces !important;
}

html[theme=light] .bp3-input.json-viewer-debug-textarea {
    background-color: transparent;
    box-shadow: none;
    width: 900px;
    height: 400px;
}
  
html[theme=dark] .bp3-input.json-viewer-debug-textarea {
    background-color: transparent;
    box-shadow: none;
    width: 900px;
    height: 400px;  
}

