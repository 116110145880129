@import "~@blueprintjs/core/src/blueprint";

.incident-search-table {
  .runbook-status-cell .simple-tooltip {
    width: 40px !important;

    .simple-tooltip-content {
      right: 0px;
      white-space: nowrap;
    }
  }

  .settings-icon {
    right: 20px !important;
  }

  .tir-ui-table-container {
    padding-top: 25px;
  }
}

// Hide the 'Update Status' comp if the space is to small. Well supported https://caniuse.com/css-container-queries
.search-header-wrapper {
  container: incidentHeader / inline-size;
}

.search-table-container{
  display: flex;
  flex-direction: column;
  height: 100%;
}

#incidentList{
  flex-grow: 1;
  overflow-y: auto;
}

@container incidentHeader (max-width: 800px) {
  .search-header-wrapper .data-load-facade.show-contents {
    display: none;
  }
}

.facet-column-container {
  overflow-y: auto;
}

.incident-search-page-dialog {
  width: 900px !important;
}

.custom-property-bubble {
  text-wrap: nowrap;
  border-radius: 6px;
  color: #f5f8fa;
  padding: 0 8px;
  margin-right: 8px;
  margin-bottom: 5px;
  line-height: 2 !important;
  display: inline-block;

  &.color1 {
    background-color: #B041DA;
  }

  &.color2 {
    background-color: #B041DA;
    /*background-color: #DBA62E;*/
  }

  &.color3 {
    background-color: #B041DA;
    /*background-color: #749ED5;*/
  }

  &.color4 {
    background-color: #B041DA;
    /*background-color: #7466B7;*/
  }

  &.color5 {
    background-color: #B041DA;
    /*background-color: #00D34E;*/
  }
}

.search-facade .left-column {
  padding: 0 0.5rem 0.5rem;
  border-radius: 10px;
  margin: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 320px !important;
}

.search-facade-runbook-scheduling-page {
  .left-column,
  .search-header-bar>div:first-child,
  .search-header-bar>div:first-child + div,
  .search-result-count {
    /* Adjust this based on feedback from Tim and Jeff */
    //display: none !important;
  }
}

html[theme=light] .search-facade .left-column {
  background-color: #FFF;
}

html[theme=dark] .search-facade .left-column {
  background-color: #161A25;
}

html[theme="light"] .#{$ns}-input.indicator-debug-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}

html[theme="dark"] .#{$ns}-input.indicator-debug-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}

html[theme=light] .pagination-sticky {
  background: #e8ecef;
}

html[theme=dark] .pagination-sticky {
  background: #242833;
}

.pagination-sticky {
  padding: 20px 0;
}

.deleted-item {
  color: rgba(92, 112, 128, 0.6);
}

.loading-overlay {
  color: white;
}

table p.RelativeTimeCell {
  display: flex;
  flex-direction: column;

  span.RelativeTimeCell-start {
    opacity: 0.7;
  }
}

// Schedule runbooks wizard styles
.schedule-runbook-modal {
  .#{$ns}-dialog-body {
    height: 500px;
  }
}

.capacity-planning-modal {
  .#{$ns}-dialog-body {
    height: 650px;
  }
}

.days-of-week {
  display: flex;

  .#{$ns}-control input:checked ~ .#{$ns}-control-indicator {
    background: #137cbd !important;
  }
  .#{$ns}-control.#{$ns}-disabled input:checked ~ .#{$ns}-control-indicator {
    background: #9cb2c0 !important;
  }

  $days: "S", "M", "T", "W", "T", "F", "S";

  @for $i from 1 through length($days) {
    $day: nth($days, $i);

    .#{$ns}-checkbox.#{$ns}-inline:nth-child(#{$i})
      .#{$ns}-control-indicator::before {
      content: "#{$day}" !important;
    }
  }

  .#{$ns}-form-content {
    .#{$ns}-checkbox {
      &:first-child {
        .#{$ns}-control-indicator {
          border-radius: 5px 0 0 5px !important;
        }
      }
      &:last-child {
        .#{$ns}-control-indicator {
          border-radius: 0 5px 5px 0 !important;
        }
      }
    }
  }

  .#{$ns}-control-indicator {
    background: #cfcfcf !important;
    margin-right: -1px !important;
    font-size: 21px !important;
    border-radius: 0 !important;
    height: 1.3em !important;
    width: 1.3em !important;
    margin-top: 0 !important;

    &::before {
      background: none !important;
      text-align: center;
      position: absolute;
      top: 4px;
      color: white;
      height: 1.3em !important;
      width: 1.3em !important;
      font-weight: 500;
    }
  }

  .#{$ns}-inline {
    margin: 0 !important;
  }
}

.on-demand-runbook-status-refresh {
  white-space: nowrap;
  margin-top: 5px;

  span {
    font-size: 0.75rem;
    font-weight: 400;
  }
}

td:has(.on-demand-runbook-status-refresh) {
  white-space: nowrap;
}

.#{$ns}-html-select.#{$ns}-disabled {
  background-color: #e9ecef !important;
}
.#{$ns}-html-select select:disabled {
  box-shadow: 0 0 0 1px inset rgba(140, 152, 164, 0.25) !important;
}

html[theme="light"] .#{$ns}-card {
  padding: 16px;
}

html[theme="light"] .#{$ns}-dialog.ColumnChooser-container,
html[theme="dark"] .#{$ns}-dialog.ColumnChooser-container {
  width: 60vw;
}

.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container
  .#{$ns}-card.ColumnChooser-card,
.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container
  .#{$ns}-card.ColumnChooser-card {
  margin-bottom: 1vh;
}

.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-large
  .#{$ns}-card
  section.ColumnChooser-column-left
  ul.ColumnChooser-list,
.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-large
  .#{$ns}-card
  section.ColumnChooser-column-right
  ul.ColumnChooser-list {
  height: 90%;
}

.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-medium
  .#{$ns}-card
  section.ColumnChooser-column-left
  ul.ColumnChooser-list,
.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-medium
  .#{$ns}-card
  section.ColumnChooser-column-right
  ul.ColumnChooser-list {
  height: 85%;
}

.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-small
  .#{$ns}-card
  section.ColumnChooser-column-left
  ul.ColumnChooser-list,
.#{$ns}-dialog-container
  .#{$ns}-dialog.ColumnChooser-container.Container-small
  .#{$ns}-card
  section.ColumnChooser-column-right
  ul.ColumnChooser-list {
  height: 80%;
}

html[theme="light"]
  .ColumnChooser-container
  .#{$ns}-button.#{$ns}-outlined.#{$ns}-intent-primary {
  color: #fff;
}

.ColumnChooser-column-right ul li.SortableItem-list button {
  margin-left: auto;
}

html[theme="light"] ul li button:not(:disabled),
html[theme="light"] ul li [type="button"]:not(:disabled) {
  cursor: grab;
}

.ColumnChooser-container
  .ColumnChooser-card
  section.ColumnChooser-column-middle
  button {
  min-width: 100px;
}

html[theme="light"]
  .ColumnChooser-container
  .ColumnChooser-card
  section
  ul.ColumnChooser-list
  li:hover {
  background-color: rgba(33, 37, 41, 0.2) !important;
}

html[theme="dark"]
  .ColumnChooser-container
  .ColumnChooser-card
  section
  ul.ColumnChooser-list
  li {
  background-color: inherit !important;

  &:hover {
    background-color: white !important;
    label {
      color: #242833 !important;
    }
  }
}

.left-column:has(.runbook-scheduling-column) {
  opacity: 0.4;
  pointer-events: none;
}

.runbook-scheduling-column {
  .search-header-bar {
    & > div:first-child,
    & > div:nth-child(2) {
      opacity: 0.4;
      pointer-events: none;
    }
  }
}

.runbook-scheduling-page-dialog {
  max-width: 500px !important;
}

.merge-and-split-buttons {
  margin-right: 20px !important;
  &+.d-flex.ml-auto {
    margin-left: 0 !important;
  }
}

.merged-custom-properties {
  .json-viewer-content {
    min-width: 100%;
  }
}

