@import "../../../utils/themes/common/variables";

.detail-pane-container {
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15);
  z-index: $pt-z-index-content - 1;
  &.floating {
    margin: 0;
    position: absolute;
    z-index: 15;
    top: 0;
    bottom: 0;
    right: 0;
  }

  .detail-pane-buttons {
    display: flex;

    > .detail-pane-buttons-left {
      flex: 1;
    }
  }

  .detail-pane-content {
    margin: $pt-grid-size;
  }
}

// manage theme
body {
  &.#{$ns}-dark {
    .detail-pane-container {
      background-color: $dark-gray4;
    }
  }
}
