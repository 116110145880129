@import "../variables";
.donut-tile {
    .card-chart-holder {
        min-height: $donut-chart-min-width;
        min-width: $donut-chart-min-height;
    }
    .highcharts-container {
        display: inline-block;
    }
    .center-content {
        @include chart-overlay-content;
    }
    &:hover .center-content {
        @include chart-overlay-content-hover;
    }
}