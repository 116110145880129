.alertEventContent {
  margin-block: 0.1em;
  height: 100%;
}

.alertEventTitle {
  margin-block: 1em;
  font-size: medium;
}

.alertEventTitle-small {
  font-size: small;
}

.alertEventTitle-medium {
  font-size: medium;
}

.alertEventMessage {
  margin-block: 0.4em;
  height: 70%;
}

.alertEventMessageContent {
  display: flex;
}

.alertEventText-medium {
  font-size: small;
  font-weight: 500;
  padding-top: 0.5em;
}

.alertEventText-small {
  font-size: x-small;
  font-weight: 500;
  padding-top: 0.7em;
}
