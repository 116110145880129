@import "~@blueprintjs/core/src/blueprint";

.date-time-selector {
  display: inline-flex;
  .date-time-selector-option-button {
    width: 40px;
    height: 27px;
    letter-spacing: 1.6px;
    .#{$ns}-button-text {
      padding-bottom: 8px;
    }
  }
  
  .date-time-selector-more-button-selected {
    border-radius: 3px !important;
  }
    
  .date-time-selector-date-picker {
    display: inline-block;
    .date-time-selector-date-control {
      background-color: inherit;
      .DayPicker, .DayPicker-Months, .DayPicker-Month {
        width: 100%;
      }
      .DayPicker-Month {
        margin: 0;
      }
      .#{$ns}-timepicker:first-of-type {
        margin-right: 5px;
      }
      .#{$ns}-timepicker-arrow-row {
        text-align: left;
      }
    }
  }
  .#{$ns}-datepicker {
    .DayPicker-Day.DayPicker-Day--selected {
      border-radius: 15px;
    }
    .DayPicker-Day--selected-range-start:not(.DayPicker-Day--selected-range-end):not(.DayPicker-Day--hovered-range-end) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .DayPicker-Day--selected-range-end:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--hovered-range-start) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}


html[theme="dark"] {
  .date-time-selector-popover{
    .#{$ns}-popover-arrow-fill {
      fill: #30404d !important;
    }
  }
} 
