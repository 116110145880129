.time-range-container{
    display: flex;
    flex-direction: row;
}

.time-range-container > * {
    margin-right: 7px;
}

.time-range-live-checkbox {
    position: relative;
    top: 6px;
}

.time-range-select-view{
    display: flex;
    vertical-align: middle;
    height: 65px;
}
.time-range-select-view-right{
    display: flex;
    vertical-align: middle;
    justify-content: flex-end;
    margin-top: 18px;
}
.arrow-button {
    color:#5c7080
}

.arrow-button:hover {
    color:#343a40
}