.graph-toolbar {
  display: flex;
  align-items: center;
  height: 100%;
}

.graph-toolbar-separator {
  height: 20px;
  width: 1px;
  background: #999;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
}

.graph-toolbar-button {
    cursor: pointer;
}

.graph-toolbar .layout-button {
    transform: rotate(-90deg);
}

.graph-toolbar a.toolbar-warning-button {
    padding-left: 4px;
    padding-right: 4px;
}

.graph-toolbar a.toolbar-error-button {
    padding-left: 4px;
    padding-right: 4px;
}

.graph-toolbar .toolbar-warning-button:hover,
.graph-toolbar .toolbar-error-button:hover {
    background: none !important;
}
.unsaved {
    margin: 0 auto -8px;
    line-height: .5;
}

.version {
    margin: 0 auto -8px;
    line-height: .5;
}

.runbook-title-wrapper {
    max-width: 540px;
    margin: 0 auto;

    .icon-title {
        max-width: 480px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
