
.priority-reasons-side-panel {
    z-index: 20;
}

ul.priority-reasons-list {
    list-style-type: square;
    padding-inline-start: 20px;
}

ul.priority-reasons-list li::marker {
    font-weight: bold;
}

li.priority-reason.critical::marker {
    color: #c74141;
}

li.priority-reason.high::marker {
    color: #f28349;
}

li.priority-reason.moderate::marker {
    color: #fcda5d;
}

li.priority-reason.low::marker {
    color: #cbd5e0;
}
