@import "variables";

@mixin sub-nav-header-style {
  padding: 0 2rem 0 2rem;
  z-index: 1;
}

html[theme=light] {
  .sub-nav-header {
    .#{$ns}-navbar {
      background-color: #f5f4f4;
      box-shadow: 0 0.1rem $light-gray2;
      @include sub-nav-header-style;
    }
  }
}

html[theme=dark] {
  .sub-nav-header {
    .#{$ns}-navbar {
      background-color: #181d2a;
      box-shadow: 0 0.1rem #161b26;
      @include sub-nav-header-style;
    }
  }
}


//html[theme=light] {
//  .sub-nav-header {
//    background-color: $light-gray5;
//    .#{$ns}-navbar {
//      background-color: $light-gray5;
//    }
//  }
//}
//
//html[theme=dark] {
//  .sub-nav-header {
//    background-color: $light-gray5;
//    .#{$ns}-navbar {
//      background-color: $light-gray5;
//    }
//  }
//}
