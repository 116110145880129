@import "~@blueprintjs/core/src/blueprint";

.sidebar {
  background-color: #282b3e !important;
  .#{$ns}-menu {
    padding: 0 !important;
    background-color: #282b3e !important;
    border-radius: 0 !important;

    a.#{$ns}-menu-item {
      & > .#{$ns}-icon:first-child {
        margin-right: 12px !important;
      }
      &:hover {
        .#{$ns}-icon {
          color: #e0dede;
        }
      }
      background-color: transparent !important;
      margin: 0;
      font-weight: 300;
      padding: 12px 10px 12px 18px !important;
      color: #dcd8d8 !important;

      &.#{$ns}-active {
        background-color: #34384b !important;
        color: #eeeaea !important;

        .#{$ns}-icon {
          color: #e0dede !important;
        }
      }

      .#{$ns}-icon {
        color: #5c7080
      }
    }

  }
  .#{$ns}-submenu.#{$ns}-popover {
    padding: 1px !important;
    .#{$ns}-popover-content {
      border-radius: none !important;
      .#{$ns}-menu {
        .#{$ns}-menu-item {
          padding: 12px !important;
        }
      }
    }
    & > .#{$ns}-popover-content {
      box-shadow: none !important;
    }
  }
  .toggle-docking {
    right: -12px !important;
  }
}

.sidebarShowOver {
  z-index: 120 !important;
}

// NOTE:
// If the change gets accepted OK, 
// we can integrate this file with 
// 'src/utils/themes/common/components/_navbars.scss' and use variables.
// This way is easier to revert.
