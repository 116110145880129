@import "utils/themes/common/variables";
.aggregate-control .grid-container {
    display: grid;
    grid-template-columns: .7fr 1.5fr 2fr 2fr;
    grid-auto-rows: auto;
    grid-gap: 20px;
}

html[theme=dark] {
    .aggregate-control {
        background-color: $dark-gray4;
    }
}

html[theme=light] {
    .aggregate-control {
        background-color: $light-gray4
    }
}

.aggregate-control {
    min-height: 100px;
}