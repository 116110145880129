@import 'variables.scss';

.support-form {
    padding-top: 5px;
    .input-field {
        width: 300px;
    }

    .subject-field {
        width: 525px;
    }

    .description-field {
        width: 525px;
        height: 150px;
    }
}

.#{$ns}-alert {
    max-width: 550px;
    &.#{$ns}-dialog {
        width: 550px;
    }
}
