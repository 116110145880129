.formatted-note {

    .row-padding {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    position: relative;
    .note-controls>button {
        top: -20px;
        opacity: 0;
        transition: top 0.1s, opacity 0.1s !important;
        &:nth-of-type(2) {
            transition-delay: 0.04s !important;
        }
    }
    &:hover {
        z-index: 100!important;
    }
    // When user hovers over the node
    &:hover {
        .note-controls>button {
            top: -30px;
            opacity: 1;
        }
    }

    &show-border .desc {
        border-left: 1px solid #D9D9E0;
    }

    .title {
        margin-left: 1px;
    }

    .note-controls {
        position: absolute;
        right: 10px;
        top: 0;
        z-index: 110!important
    }
}
