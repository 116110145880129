.#{$ns}-button {
  border-radius: 20px !important;
  box-shadow: none !important;

  &.#{$ns}-intent-primary,
  &.#{$ns}-intent-success {
    background-color: #E26E44;
    border: 2px solid #E26E44;

    &:not(.#{$ns}-disabled) {
      &:hover {
        background-color: #CE6046 !important;
        border: 2px solid #CE6046;
      }
    }

    &.bp3-disabled {
      background-color: #E26E44; //disabled color, bg & text
    }
  }

  &.#{$ns}-outlined {
    border: 1px solid #000091 !important;
    // &:hover {
    //   border: 1px rgba(52, 58, 64, 0.3) !important;
    // }
  }

  // &.#{$ns}-minimal {
  //   padding: 0 !important;
  // }

  // &:not(.#{$ns}-minimal):not([data-testid^="page-button"]):not(.filter-toggle-button) {
  //   &:has(span[icon]) {
  //     min-width: unset !important;
  //   }
  //   min-width: 100px !important;
  // }
}

// Wrapper specific. Change manually direct on the component
.blade-container-content {
  .top-toolbar {
    .#{$ns}-button.#{$ns}-minimal {
      background-color: white;

      &:hover {
        color: #000091;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, .1) !important;
      }
    }
  }

  tfoot {
    button.#{$ns}-button {
      &:not([class*="bp3-intent-"]) {
        border: 1px solid #000091;

        &:hover {
          background: rgba(52, 58, 64, 0.3);
        }
      }
    }
  }
}

// Wrapper specific. Change manually direct on the component
.row-sub-component-container {
  .bp3-button-group .bp3-button {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .1) !important;
    &:hover {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .8) !important;
    }
  }
}

// Wrapper specific. Change manually direct on the component
.time-range-buttons {
  .bp3-outlined {
      border: none !important;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .2) !important;
  }
}

.#{$ns}-button-group {
  .#{$ns}-button:not(.#{$ns}-disabled) {
    &:hover {
      color: #000091;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, .1) !important;
    }
  }

  &:not(.#{$ns}-minimal) {
    & .#{$ns}-button {
      &:not(:last-of-type) {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-right: 2px solid #e8ecef !important;
      }

      &:not(:first-of-type) {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-left: 2px solid #e8ecef !important;
      }
    }
  }
}
