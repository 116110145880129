.sub-header-props {
    background-color: inherit !important;
}

.incident-details-side-panel {
    z-index: 19;
}

.absolute-scrollbar-space {
    right: 6px;
    bottom: 6px;
}
