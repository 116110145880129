.wrap-field-in-display-mode {
    &.clickable {
        cursor: pointer;
    }
    &.display-mode.empty {
        display: inline-block;
        width: 135px;
        height: 22px;
    }
}
