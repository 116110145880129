@import "../../../../utils/themes/common/variables";

html[theme=dark] {
    .filter-autocomplete-input input {
        border: 1px solid $gray1!important;
    }
}

.filter-toggle-button {
    position: relative;
    &.#{$ns}-active {
        box-shadow: 0 0 5px $normal-color !important;
    }
    &.has-filters {
        .#{$ns}-icon {
            margin-right: 0 !important;
        }
        .#{$ns}-button-text {
            position: absolute !important;
            top: -4px;
            right: -4px;
            font-size: 9px;
            line-height: 15px;
            width: 15px;
            height: 15px;
            background: $black;
            color: $white;
            border-radius: 50%;
            text-align: center;
        }
    }
}
.global-filter-popover.#{$ns}-select-popover .#{$ns}-menu {
    max-height: 500px;
}
html[theme=dark] {
    .filter-toggle-button.has-filters .#{$ns}-button-text {
        line-height: 14px;
        border: 1px solid $white;
    }
}