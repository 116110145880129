.bp3-dialog-container .bp3-dialog.modal-container {
    width: 800px;
}

.d-flex-main {
    display: flex;
    gap: 32px;
}

.d-flex-second {
    display: flex;
    gap: 16px;
}

.d-flex-main
    div.bp3-form-group {
    flex: 1 1 auto;
}

.label-width {
    width: 165px;
}

.name-field {
    width: 100%;
}

.hide {
    visibility: hidden;
}

#daysExpirationTime {
    width: 80px;
}
