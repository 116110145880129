// Importing bootstrap variables to have access below
@import '../common/variables';

// Variable overrides
$font-size-base: 0.9rem;
$grid-gutter-width: 10px;
$spacer: .8rem;
$blue: rgb(43, 65, 82);
$card-spacer-x: .5rem;
$card-columns-gap: 1rem;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
// $white: #F9F9F9;
$black: #111;
$secondary: $gray-500;
$light: $gray-400;
$body-bg: $gray-200;
$body-color: $gray-800;
$component-active-color: $black;
$link-color: lighten(theme-color("primary"), 15%);
$link-hover-color: lighten($link-color, 15%);
$border-radius: 0.5rem;
$card-bg: $white;
$card-border-radius: $border-radius;
$dropdown-link-hover-bg: $gray-200;
$box-shadow-color: rgba(140,152,164,.25);
