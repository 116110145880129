.simple-tooltip-target {
    .simple-tooltip {
        display: none;
        .simple-tooltip-content {
            border-radius: 5px;
            top: 2px;
            z-index: 10;
        }
    }
    &:hover .simple-tooltip {
        display: block;
    }
}
