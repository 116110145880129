.variables-container {
    overflow-y: auto;
    width: 60vw;
    height: 100%;
}

.add-variable-control {
    border-style: dashed;
    border-width: 2px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 1em 0 0.5em 0;
    justify-content: center;
    align-items: center;
    div.bp3-popover2-arrow:not(.bp3-minimal) {
        // The variable control panel is a minimal popover
        // This is a workaround to display other popovers arrows since they are all affected by bp3-minimal class
        display: block;
    }
}

.save-btn {
    display: flex;
    flex-direction: column;
}

.variables-grid-header {
    display: grid;
    grid-template-columns: 1em 1.2fr 1fr 0.8fr 0.8fr 1.1em;
    gap: 0.5rem;
}

.variables-grid {
    display: grid !important;
    grid-template-columns: 1em 1.2fr 1fr 0.8fr 0.8fr 1em;
    gap: 0.5rem;
}

html[theme=dark] {
    .properties-background {
        background-color: rgba(19, 124, 189, 0.5);
    }
}

html[theme=light] {
    .properties-background {
        background-color: #DCE7F2;
    }
}

.properties {
    border-radius: 8px;
    .properties-grid {
        display: grid;
        grid-template-columns: 1.8em 1.1fr 1fr 0.8fr 0.8fr 0.5em;
        gap: 0.5rem;
    }
}

.variable-list {
    max-height: 45vh;
    overflow: auto;
}