@import "../../../../utils/themes/common/variables";

.dashboard-list-table {
  .dashboard-action-cell button {
    opacity: 0;
    position: relative;
    left: -1rem;
    transition: left $transition-speed-1, opacity $transition-speed-1;
  }
  tr:hover .dashboard-action-cell button {
    opacity: 1;
    left: 0;
  }

  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr td,
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr td:not(:first-child),
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr:not(:first-child) td,
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr:not(:first-child) td:not(:first-child) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
