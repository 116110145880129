.rounded-link-button {
    border-radius: 15px !important;
    border: 1px solid transparent !important;
    &.size-s {
        font-size: 0.65rem !important;
        min-height: 20px !important;
        padding-right: 8px !important;
        padding-left: 8px !important;
    }
    &.size-xs {
        font-size: 0.6rem !important;
        line-height: 0.6rem;
        min-height: 10px !important;
        padding-right: 3px !important;
        padding-left: 3px !important;
    }
}

html[theme=light] {
    .bp3-outlined {
        border: 1px solid rgba(52, 58, 64, 0.2) !important;
    }
}

html[theme=dark] {
    .bp3-outlined {
        border: 1px solid rgba(222, 226, 230, 0.2) !important;
    }
}
