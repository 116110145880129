.chart-toolbar {
    width: 100%;
    text-align: end;
}

.chart-toolbar.hover-only {
    opacity: 0;
}

.chart-toolbar.hover-only:hover {
    opacity: 1;
}

.chart-toolbar-button {
    cursor: pointer;
}

