@import 'variables.scss';

.tir-edge-gateways {
	&.main-content {
		height: 470px;
	}

	&.panel-content {
		height: 430px;
		display: flex;
		flex-direction: column;
	}
}
