@import "~@blueprintjs/core/lib/scss/variables";

.range-slider .offset-left {
    margin-left: -11px;
    z-index: 3
}
html[theme=light] {
    .range-slider .handle {
        color: $blue2;
    }
}
html[theme=dark] {
    .range-slider .handle {
        color: $blue5;
    }
}

.range-slider .handle:hover {
    cursor: grab;
}

.handle-label-base {
    position: absolute;
    margin-top: -6px;
    width: 80px;
    font-size: 13px;
}

html[theme=light] {
    .range-slider .handle-label {
        @extend .handle-label-base;
        color: $gray1;
    }
}
.range-slider .start{
    margin-left: -60px;
}
.range-slider .end{
    margin-left: 10px;
}

html[theme=dark] {
    .range-slider .handle-label {
        @extend .handle-label-base;
        color: $gray5;
    }
}

.label-base {
    display: inline;
    position: absolute;
    font-size: 12px;
    margin-top: 6px
}

html[theme=light] {
    .range-slider .label {
        @extend .label-base;
        color:$gray1
    }
}

html[theme=dark] {
    .range-slider .label {
        @extend .label-base;
        color: $gray5
    }
}

.circle-center-base {
    margin-left: -8px;
    margin-top: 12px;
    z-index: 10
}

html[theme=light] {
    .range-slider .circle-center {
        @extend .circle-center-base;
        color: $blue3;
    }
}

html[theme=dark] {
    .circle-center {
        @extend .circle-center-base;
        color: $blue3;
    }
}

.marker-base {
    width: 4px;
    height: 3px;
    margin-top: 0px;
}

html[theme=light] {
    .range-slider .marker {
        @extend .marker-base;
        background-color: $white;
    }
}

html[theme=dark] {
    .range-slider .marker {
        @extend .marker-base;
        background-color: $black;
    }
}

.range-slider {
    display: inline-block;
    width: 100%;
    min-width: 150px;
    position: relative;
}

html[theme=light] {
    .range-slider .pit {
        @extend .slider-base;
        background: $light-gray1 ;
    }
}

html[theme=dark] {
    .range-slider .pit {
        @extend .slider-base;
        background: $dark-gray4;
    }
}

.slider-base {
    position: absolute;
    height: 3px;
    top: 0px;
}

html[theme=light] {
    .selected-range {
        @extend .slider-base;
        background: $blue5;
    }
}
.selected-range:hover {
    cursor: grab;
}
html[theme=dark] {
    .selected-range {
        @extend .slider-base;
        background: $blue5;
    }
}

html[theme=dark] {
    .track {
        @extend .slider-base;
        background: $dark-gray4;
    }
}
html[theme=light] {
    .track {
        @extend .slider-base;
        background: $light-gray1;
    }
}