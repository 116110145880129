@import "../../../../utils/themes/common/variables";
.single-page-layout {
    display         : flex;
    height          : 100vh; //verify ie11 compatible
    width           : 100%;
    flex-direction  : column;
    justify-content : center;
    align-items     : center;
    background-image    : url(../../../../assets/images/layout/corner_waves.png);
    background-position : right bottom;
    background-repeat   : no-repeat;
    padding-top     : 25px;


    .cc-accent-line {
        width      : 100%;
        height     : 5px;
        position   : absolute;
        top        : 0;
        left       : 0;
        background : -webkit-gradient(linear, left top, right top, from(#ff6900), to(#ffb022));
        background : linear-gradient(to right, #ff6900, #ffb022);
        z-index    : 1031;
    }

    .riverbed-logo {
        margin-bottom : 20px;
        text-align    : center;

        > img {
            height        : 40px;
            margin-bottom : 10px;
        }
    }

    .footer {
      width      : 100%;
      position   : absolute;
      bottom     : 0;
      text-align: center;
    }
}
