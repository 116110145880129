@import 'variables.scss';

.edit-dashboard-dialog {
	&.main-content {
    width: 1250px!important;
    min-width: 1250px!important;
		height: 950px;
    .bp3-dialog-header {
      z-index: 100;
    }
	}

	&.panel-content {
    max-width: 900px;
		height: 900px;
		display: flex;
		flex-direction: column;
	}
}

.react-grid-item {
  overflow: auto;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0,0,0,0.1);
}

.widget-headings {
  border-bottom: 1px solid rgba(16, 22, 26, 0.15);
  cursor: move;
  border-radius: 10px 10px 0 0;
  position: sticky;
  top: 0;
  z-index: 1;
}
