@import "../../../../utils/themes/common/variables";

.runbook-graph {
    .show-blade-control {
        position: absolute;
        top: 50%;
        right: 0;
        cursor: pointer;
        z-index: 15;
    }
    .graph-content {
        position: absolute;
        top: 0;
        bottom: 0;
        &:not(.in-dialog) {
          left: 0;
          right: 0;
        }
        &.in-dialog {
          width: 100%;
        }
        .react-flow__node {
            transition: opacity 0.5s;
        }
        &.node-selected .react-flow__node{
            opacity: 0.5 !important;
            &.selected, &:hover {
                opacity: 1 !important;
            }
        }
    }
}

.saving-scheduled-runbook {
  .bp3-callout {
    &.bp3-callout {
      text-align: justify;
      padding-right: 30px;
    }
  }
}

// Dynamic text truncate for Blade Title - (add steps as needed)
.titleContainer {
  container: tituloContainer / inline-size;
}

@container tituloContainer (width < 450px) {
  .dinamicTruncate {
    width: 290px;
  }
}
@container tituloContainer (width > 450px) and (width < 610px) {
  .dinamicTruncate {
    width: 400px;
  }
}
