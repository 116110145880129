.col {
    padding-bottom: $spacer;
}
.card {
    box-shadow: 0 6px 12px $box-shadow-color;
    color: $text-muted;
    font-size: .85rem;
    transition: box-shadow $transition-speed-1, width $transition-speed-1, height $transition-speed-1, background-color $transition-speed-2;
    // width: 100%;
    overflow: auto;
    .tile-icon {
        color: $orange;
        opacity: 0.7;
        transition: opacity $transition-speed-1;
        :hover {
            opacity: 1;
        }
    }
    h3, h5, h6, table {
        color: $text-muted;
        transition: color $transition-speed-1, font-size $transition-speed-2;
    }
}
.card:hover {
    background-color: lighten($body-bg, 5%);
    .bp3-icon {
        opacity: 1;
    }
}
.panel:hover {
    background-color: lighten($body-bg, 5%);
    .bp3-icon {
        opacity: 1;
    }
    h3, h5, h6 {
        color: $body-color;
    }
}
.chart-holder {
    min-height: 300px;
    min-width: 370px;
    overflow: hidden;
}
.panel {
    background-color: $navbar-bg;
    color: $text-muted;
    font-size: .85rem;
    transition: box-shadow $transition-speed-1, width $transition-speed-1, height $transition-speed-1, background-color $transition-speed-2;
    width: 100%;
    .tile-icon {
        color: $orange;
        opacity: 0.7;
        transition: opacity $transition-speed-1;
        :hover {
            opacity: 1;
        }
    }
    h3, h5, h6, table {
        color: $text-muted;
        transition: color $transition-speed-1, font-size $transition-speed-2;
    }
}
.chart-holder {
    min-height: 300px;
    min-width: 370px;
    overflow: hidden;
}

.clickable-table tr {
    cursor: pointer;
    transition: box-shadow $transition-speed-1;
}
.clickable-table tr:hover {
    box-shadow: 0 0 8px $blue2;
}
 
.tir-ui-pagination {
    padding: 5px;
    white-space: nowrap;
}
.tir-ui-pagination .page-info {
    margin-right: 10px;
}
.tir-ui-pagination select {
    min-width: 100px;
}
.absolute-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}