.paywall-portal {
    .bp3-dialog-container .paywall-background {
        display         : flex;
        height          : 270px;
        width           : 550px;
        border-radius: 12px;
        color: #EBEBEB;
        text-align: center;
    }
    
    div.bp3-overlay-backdrop {
        background-color: transparent;
    }

    button.bp3-button.paywall-button {
        width: 180px;
        padding: 8px;
        border-radius: 18px;
        font-size: 22px;
        color: #E10098;
        font-weight: 700;
        margin-bottom: 25px;
    }

    .bp3-dialog-body {
        p {
            margin: 25px;
            line-height: 1.2;
            font-size: 22px;
        }
        h1 {
            margin: 25px;
            font-size: 24px;
            font-weight: 800;
            + p {
                font-size: 16px;
            }
        }
    }
}