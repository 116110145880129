.info-content-content {
    overflow-y: auto;
    height: calc(100% - 70px);
}

.info-content-content .bp3-tree-node-label {
    user-select: text !important;
}

.info-content-content .info-warning-error-label .bp3-tree-node-content {
    min-height: 30px;
    height: unset !important;
}

.info-content-content .info-warning-error-label .icon-and-label-div {
    white-space: break-spaces !important;
    overflow-x: auto;
    overflow-y: hidden;
}

.stacktrace-container {
    overflow: scroll;
}