.user-preferences-debug-dialog {
    width: 700px !important;
}

.user-preferences .standard-text-control-width {
    width: 250px;
}

.user-preferences {
  padding: 20px;
  border-radius: 10px;
}

html[theme=light] .user-preferences {
  background-color: #FAFAFA;
}

html[theme=dark] .user-preferences {
  background-color: #161A25;
}

.user-preferences-section {
  border-radius: 5px;
  border: solid 2px;
  padding: 8px 15px;
  overflow-x: auto;  
}

html[theme=light] .user-preferences-section {
  background-color: #FFFFFF;
  border-color: #E4E4E4 !important;
}

html[theme=dark] .user-preferences-section {
  background-color: #2f3442;
  border-color: transparent !important;
}
