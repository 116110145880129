.subflow-editor-tabs {
  &#{&} {
    margin-left: -0.8rem;
    .bp3-tab-list {
      padding-left: 0;
      .bp3-tab {
        font-size: large;
        text-transform: none;
      }
    }

    .tabbed-subpage-tab {
      padding-left: 0.8rem;
      td {
        white-space: normal;
        select {
          font-size: 13px;
          padding: 0 20px 0 6px;
        }
        span {
          word-wrap: break-word;
        }
      }
    }

    .selector-min-width {
      min-width: 90px;
      option:disabled {
        display: none !important;
      }
    }

    .selector-min-width.method select {
      text-transform: capitalize;
    }
  }
}

.resizable-details-panel:has(.subflow-editor-tabs) {
  flex: 0 0 720px;
  .edit-subflow-warning {
    font-size: 14px;
  }
}
  