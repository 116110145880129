@import "utils/themes/common/variables";

html[theme=dark] {
    .bp3-dark .bp3-dialog.propertiesModal {
        background-color: #0a112e;
    }

    .bp3-dark .bp3-dialog.propertiesModal .bp3-dialog-header {
        background-color: #445e87;
    }
}

.bp3-dialog-container .bp3-dialog.propertiesModal {
    width: 80vw;
}
