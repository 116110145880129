.icon-title {
  display: inline-flex;
  word-wrap: break-word;
  word-break: break-all;

  .led-holder {
    background-color: #000091;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    // transform: rotate(-45deg);
    border-radius: 50%;

    & > * {
      transform: rotate(45deg);
    }

    .size-s {
      width: 15px;
      height: 15px;
      min-width: 15px;
      min-height: 15px;
    }

    span[icon] {
      transform: scale(2);
    }
  }

  .title-holder {
    white-space: nowrap;
  }
}
