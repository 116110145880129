
.bp3-dialog {
	position: relative;
	.referencing-runbooks-dialog.bp3-dialog-body {
		margin: 0px;
		height: 600px;
		overflow-y: auto;
	}
}

.bp3-portal.error-dialog {
	z-index: 21;
}

.third-party-authentication-error-modal {
	word-wrap: break-word;
}

.referencing-runbooks-dialog {
	.show-contents {
		max-height: 80vh;
		overflow: auto;
	}
}