.time-range-buttons {
    display: flex;
    margin: 0px 0px 15px 15px;
    .bp3-outlined {
        margin-right: 10px;
    }
}

.indicator-detailed-view-dialog {
  width: 900px !important;
}

html[theme=light] .bp3-input.indicator-debug-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}

html[theme=dark] .bp3-input.indicator-debug-textarea {
  background-color: transparent;
  box-shadow: none;
  width: 900px;
  height: 400px;
}
