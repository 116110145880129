.react-flow__node-subflow {
    background: #fff;
}

.react-flow__node-subflow {
    padding: 10px;
    width: 150px;
    font-size: 12px;
    color: #222;
    text-align: center;
    border-width: 1px;
    border-style: solid;
}

.react-flow__node-subflow.selected,
.react-flow__node-subflow.selected:hover {
/*    box-shadow: 0 0 0 0.5px #00ff72;*/
    box-shadow: 0 0 0 1px #1a192b;
}

/*
.react-flow__node-subflow.selectable:hover {
    box-shadow: 0 1px 4px 1px rgb(0 0 0 / 8%);
}
*/
