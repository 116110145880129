.react-flow__node-skeleton {
    background: #eaecef;
    border: 2px dashed #b7b7b7;
    border-radius: 7px;
    color: #949494;
    font-size: 12px;
    opacity: .8;
    padding: 8px;
    width: 150px;
    text-align: center;
    cursor: pointer;

    &:hover, &:focus {
        background: #e0e0e0;
    }

    &.selected, &.selected:hover {
        background: #e0e0e0;
    }
}