@import "../../../../utils/themes/common/variables";

.facet-section-dialog {
  width: 700px !important;
}

.facet-settings-facet-search-table {
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr td,
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr td:not(:first-child),
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr:not(:first-child) td,
  .tir-ui-table-container table.tir-ui-table.bp3-html-table tbody tr:not(:first-child) td:not(:first-child) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.facet-section-icon {
  display: none;
  &.expanded {
    display: inline;
  }
}

.facet-section-header {
  display: flex;
  justify-content: space-between;
}

.facet-checkbox-list .facet-checkbox:first-child {
  margin-top: 15px;
}

.facet-section-container {
  border-radius: 5px;
  border: solid 2px;
  padding: 8px 15px;
  overflow-x: auto;
}

html[theme=light] .facet-section-container {
  background-color: #FFFFFF;
  border-color: #E4E4E4 !important;
}

html[theme=dark] .facet-section-container {
  background-color: #2f3442;
  border-color: transparent !important;
}

.facet-label {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  &-count {
    font-weight: 400;
  }
}

.facet-cta {
  position: sticky;
  bottom: -8px;
  padding: 25px 0 30px;
  margin: -16px 0;
  button {
    min-width: 130px !important;
  }
}
html[theme=light] .facet-cta {
  background: #fff;
  border-top: 2px solid lightgray;
}
html[theme=dark] .facet-cta {
  background: #161a25;
  border-top: 2px solid grey;
}
html[theme=light] .facet-view-header {
  background: #fff;
  border-bottom: 2px solid lightgray;
}
html[theme=dark] .facet-view-header {
  background: #161a25;
  border-bottom: 2px solid grey;
}



.facet-view-header {
  margin: -16px 0;
  padding: 0;
  position: sticky;
  top: 0px;
  z-index: 1;



  .simple-tooltip-target {
    .save-query-cta ~ .simple-tooltip {
      .simple-tooltip-content {
        left: -120px;
        top: 8px;
      }
    }
    .save-query-delete ~ .simple-tooltip {
      .simple-tooltip-content {
        left: -100px;
      }
    }
    .save-query-more ~ .simple-tooltip {
      .simple-tooltip-content {
        left: -90px;
      }
    }
  }
}

.save-query-cta {
  display: block;
  position: relative;
  top: 4px;
}

.save-query-load {
  & select {
    width: 195px !important;
  }
}

.ul-style-reset {
  list-style: none;
  padding-left: 0;
}

html[theme=light] .search-header-wrapper {
  background: #e8ecef;
}
html[theme=dark] .search-header-wrapper {
  background: #242833;
}


html[theme=light] .incident-search-table table th {
  background: #e1e1e1;
}
html[theme=dark] .incident-search-table table th {
  background: #4d4759;
}

// Somewhere this has been reset
html[theme=dark] a {
  color: #4da3ff !important;
}

.incident-search-table table tbody tr td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.incident-search-table table thead tr th {
  position: sticky;
  top: -1px;
  z-index: 9;
}

.time-period-more-option-button {
  width: unset !important;
  letter-spacing: 0 !important;
  padding: 15px 10px !important;
  .bp3-button-text {
    padding: 0 !important;
  }
}

html[theme=light] .time-period-more-option-button {
  background: #fafafa !important;
}

