@import "../variables";
.timeseries-tile {
    .card-chart-holder {
        min-width: $time-series-chart-min-width;
        min-height: $time-series-chart-min-height;
    }
    .highcharts-container {
        display: inline-block;
    }
    .highcharts-plot-band {
      opacity: 0.1;
    }
}
