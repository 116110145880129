.runbook-details-error-dialog {
    width: 570px !important;
}

.absolute-scrollbar-space {
    right: 6px;
    bottom: 6px;
}

.runbook-details-side-panel {
    z-index: 19;
    height: calc(100vh - 117px);
    overflow-y: auto;
}  

.three-dots-menu-path-traversal.three-dots-menu-path-traversal span {
    display: none !important;
}

.three-dots-menu-path-traversal.three-dots-menu-path-traversal div>.bp3-popover2-target,
.three-dots-menu-path-traversal.three-dots-menu-path-traversal div:has(>.bp3-popover2-target) span {
    display: block !important;
}