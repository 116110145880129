@import 'variables.scss';

.tir-data-source {
	padding: 15px;

	.gateway-ip-field {
		width: 100%;
	}

	.name-field {
		width: 100%;
	}

	.source-field {
		width: 100%;
	}

	.hostname-field {
		width: 100%;
	}

	.protocol-field {
		width: 98%;
	}

	.port-field {
		width: 98%;
	}

	.username-field {
		width: 100%;
	}

	.password-field {
		width: 100%;
	}
}
