@import "../../../../utils/themes/common/variables";

html[theme=light] .runbook-output-tab-runbook-bg {
  //background-color: #e9ecef;
}

html[theme=dark] .runbook-output-tab-runbook-bg {
  //background-color: #242833;
}

.runbook-outputs-dialog {
  width: 650px !important;
}

html[theme=light]  .runbook-row {
  background-color: #e9ecef;
  border-radius: 16px;
}

html[theme=dark]  .runbook-row {
  background-color: #242833;
  border-radius: 16px;
}

