.on-demand-create-variable {
    span {
        color: #106ba3;
    }
    &#{&}#{&}#{&}:hover {
        text-decoration: underline;
    }
}
.static-input-values {
    &#{&} {
        .bp3-menu-item {
            font-size: 0.875rem;
        }
        .bp3-overlay:not(:has(.bp3-menu)) {
            display: none;
        }
        .bp3-multi-select,
        .bp3-multi-select.bp3-active {
            background: #e9ecef;
            box-shadow: 0 0 0 1px inset rgba(140, 152, 164, 0.25);
        }
        .bp3-button:has(.bp3-icon-cross) {
            display: none;
        }
        .add-subflow-static-value {
            .bp3-overlay {
                display: none;
            }
            .bp3-multi-select-tag-input-input {
                display: none;
            }
            .bp3-tag-input-values {
                &:hover {
                    cursor: default;
                }
            }
            .bp3-tag:has(.highlight-item) {
                background-color: green;
            }
        }
        .multi-select-input {
            max-height: 250px;
            overflow: auto;
        }
        padding-left: 26px;
    }
}
.enter-context-values-descriptions {
    span {
        color: #106ba3;
    }
}
.edit-descriptions-dialog {
    &#{&}#{&} {
        width: 600px;
        textarea {
            padding: 6px;
            min-height: 230px;
        }
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    .validation-error {
        height: 20px;
    }
}

.subflow-input-node-editor-tabs {
    min-height: 306px;
}