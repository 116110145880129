@import 'variables.scss';

.tir-add-automation-mapping {
    &.main-content {
        height: 610px;
        max-width: 90vw;
        width: fit-content;

        .bp3-multistep-dialog-panels {
            height: 100%;
        }

        .bp3-dialog-step-viewed .bp3-dialog-step {
            cursor: pointer;
        }
    }

    .bp3-dialog-body {
        overflow-x: auto;

        // The scrollbar is not visible, and users will not know there is info about 
        // webook endpoint URL if they don't scroll
        scrollbar-color: lightgray;
        scrollbar-width: 6px;
    
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 8px rgba(0,0,0,0.3);
            background-color: lightgray;
        }

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
            background-color: lightgray;
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: gray;
            border-radius: 4px;
        }
    }

    &.panel-content {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

  .custom-wrapper {
    max-height: 325px;
    overflow: auto;
  }
}
