$navbar-bg: #1F232E;
.sidebar, .main-navbar {
    background-color: $navbar-bg;
    box-shadow: $box-shadow-sm;
    .bp3-menu, .bp3-menu-item, bp3-icon {
      color: $gray-500 !important;
      background-color: $navbar-bg;
    }
    a {
        transition: color $transition-speed-1, background-color $transition-speed-1;
        &:hover {
            text-decoration: none;
            background-color: $gray-700;
            color: $white !important;
        }
        &.bp3-active {
            color: $gray-500 !important;
        }
        &.bp3-active:hover {  
          color: $white !important;
        }
    } 
    .bp3-icon-pin {
      color: $text-muted;
      transform: rotate(-45deg);
    }
}
.sidebar {
    .bp3-menu-item {
      margin-top: 0.5rem;
      padding-left: 1rem;
    }
    .bp3-menu-divider {
      border-color: $gray1;
    }
    z-index: $pt-z-index-content + 100;
    position: absolute;
    border-style: solid;
    border-color: $navbar-bg;
    border-width: 0 0 1px 0;
    transition: top 0.2s ease-out, left 0.2s ease-in-out, flex-basis 0.3s, opacity 0.2s ease-in-out, background-color $transition-speed-1;
    left: 0;
    top: 0;
    opacity: 1;
    &.collapsed {
      left: 0;
      top: -100%;
      opacity: 0;
    }
}
.sidebar.noanim {
  transition: opacity 0.2s ease-in-out, background-color $transition-speed-1;
}
@include media-breakpoint-up(sm) {
    .sidebar {
      position: relative;
      border-width: 0 1px 0 0;

      &.collapsed {
        flex-basis: 0%;
        left: -50%;
        opacity: 0;
        width: 0;
        top: 0;
      }

      .toggle-docking {
        background-color: $navbar-bg;
        border-radius: 50%;
        position: absolute;
        right: 0;
        &:hover {
          background-color: $navbar-bg;
        }
      }

      .bp3-menu {
        padding-top: 10px;
      }
    }
    .sidebar.docked { height: 100%; }
}

.page-title h2 {
  zoom: 0.7;
}
.main-navbar .bp3-navbar-heading {
  zoom: 0.85;
}
@include media-breakpoint-up(sm) {
  .page-title h2 {
    zoom: 0.85;
  }
}
@include media-breakpoint-up(md) {
  .page-title h2,
  .main-navbar .bp3-navbar-heading {
    zoom: 1;
  }
}

