.node-editor-content {
  table {
    width: 100%;

    td {
      > * {
        padding-bottom: 10px;
      }

      &:first-child:has(label) {
        width: 1%;
        white-space: nowrap;
      }
    }
  }
  .subflowDescription {
    font-weight: 400;
    font-size: 14px;
    padding-left: 10px;
    white-space: pre-line;
  }
}

