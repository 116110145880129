// Importing bootstrap variables and functions to have access below
@import '../common/variables';

// Variable overrides
$font-size-base: 0.9rem;
$grid-gutter-width: 10px;
$spacer: .8rem;
$blue: rgb(43, 65, 82);
$card-spacer-x: .5rem;
$card-columns-gap: 1rem;
$gray-100: #161A25;
$gray-200: #242833;
$gray-300: #2F333E;
$gray-400: #6c757d;
$gray-600: #ced4da;
$gray-700: #dee2e6;
$gray-800: #e9ecef;
$gray-900: #f8f9fa;
$white: $gray-100;
$black: #F9F9F9;
$secondary: $gray-500;
$light: $gray-200;
$body-bg: $gray-200;
$body-color: $gray-700;
$component-active-color: $black;
$link-color: lighten(theme-color("primary"), 15%);
$link-hover-color: lighten($link-color, 15%);
$border-radius: 0.5rem;
$card-border-radius: $border-radius;
$card-bg: $gray-300;
$border-color: darken($gray-100, 5%);
$dropdown-link-color: $gray-800;
$card-border-color: $border-color;
$dropdown-link-hover-color: $gray-700;
$dropdown-link-hover-bg: $gray-200;

$popover-background-color: $gray-200;
$menu-background-color: $gray-200;
$dark-menu-background-color: $gray-200;
$text-muted: $gray-800;
$overlay-background-color: rgba($white, 0.7);

$scrollbar-track-color: #1F232E;
$scrollbar-thumb-color: #d5d8da;
$box-shadow-color: lighten($gray-100, 4%);