.#{$ns}-dialog {
  border-radius: 0;
}

.#{$ns}-dialog-header {
  border-radius: 0;
}

.#{$ns}-dialog-footer-actions {
  .#{$ns}-button {
    min-width: 90px;
  }

  .#{$ns}-button:not([class*="bp3-intent-"]) {
    border: 1px solid #000091;

    &:hover {
      background: rgba(52, 58, 64, 0.3);
    }
  }
}

.#{$ns}-dialog-body {
  .#{$ns}-button.#{$ns}-active.#{$ns}-outlined {
    color: #f9f9f9 !important;
    background-color: #E26E44 !important;
    border: 2px solid #E26E44 !important;

    &:hover {
      background-color: #CE6046 !important;
      border: 2px solid #CE6046 !important;
    }
  }
}

// Wrapper specific. Change manually direct on the component
.#{$ns}-dialog-footer-actions {
  .#{$ns}-button.#{$ns}-active.#{$ns}-outlined {
    color: #f9f9f9 !important;
    background-color: #E26E44 !important;
    border: 2px solid #E26E44 !important;

    &:hover {
      background-color: #CE6046 !important;
      border: 2px solid #CE6046 !important;
    }
  }
}
