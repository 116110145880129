
@keyframes nodeErrorAnimation {
    50% {
        color: red;
    }
}

.react-flow__node {
    .contents .node-controls {
        height: 0;
        overflow: hidden;
        & > * {
            position: relative;
            top: -20px;
            opacity: 0;
            transition: top 0.1s, opacity 0.1s !important;
            &.ctrl-1 {
                transition-delay: 0.04s !important;
            }
            &.ctrl-2 {
                transition-delay: 0.08s !important;
            }
        }
    }
    &:hover {
        z-index: 100!important;
    }

    &.node-warnings-and-errors {
        .warning-and-error-icons {
            position: relative;
            top: -22px;
            opacity: 1;
            transition: opacity 0.2s !important;
        }
    }
    
    .node-controls.add-menu-active > button, 
    .node-controls.add-menu-active > span {
        top: -30px;
        opacity: 1;
    }

    // When user hovers over the node or the node is selected
    &.selected {
        .contents .node-controls {
            height: auto;
            overflow: unset;
            & > button, & > span {
                top: -30px;
                opacity: 1;
            }
      }
        .warning-and-error-icons {
            opacity: 0;
        }
    }

    // Hide the controls when the node is getting dragged around. ReactFlow ends up flagging the contents
    // as dragging when user clicks it to select. To work around it, I've done it this way where I'm also
    // checking on the 'selected' class to make sure.
    &:hover:not(.selected) .contents.dragging .node-controls > button, 
    &:hover:not(.selected) .contents.dragging .node-controls > span {
        opacity: 0;
    }

    .plus-container {
      position: relative;
      right: -5px;
      z-index: 1;
      .plus-span {
        padding: 8px 0;
      }
    }
    .react-flow__handle-right.extra-plus-handle {
      right: -17px;
    }
}
.graph-static .react-flow__node .contents .node-controls {
    display: none;
}
.multiple-nodes-selected,.connection-in-progress {
    .react-flow__node .contents .node-controls {
        display: none;
    }
}
