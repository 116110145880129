.stacked-percentage-bar {
    min-height: 10px;
    .percentage-bar {
        height: 3px;
        &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }
        &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
    }
    .percentage-bar:hover {
        height: 4px;
    }
}