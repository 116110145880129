@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/variables';

$font-family-sans-serif: "museo-sans", sans-serif;

.font-size-xx-small { font-size: xx-small; }
.font-size-x-small { font-size: x-small; }
.font-size-small { font-size: small; }
.font-size-medium { font-size: medium; }
.font-size-large { font-size: large; }
.font-size-x-large { font-size: x-large; }
.font-size-xx-large { font-size: xx-large; }

/* Bootstrap 4 breakpoints */
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {
    .font-size-sm-xx-small { font-size: xx-small; }
    .font-size-sm-x-small { font-size: x-small; }
    .font-size-sm-small { font-size: small; }
    .font-size-sm-medium { font-size: medium; }
    .font-size-sm-large { font-size: large; }
    .font-size-sm-x-large { font-size: x-large; }
    .font-size-sm-xx-large { font-size: xx-large; }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {  
    .font-size-md-xx-small { font-size: xx-small; }
    .font-size-md-x-small { font-size: x-small; }
    .font-size-md-small { font-size: small; }
    .font-size-md-medium { font-size: medium; }
    .font-size-md-large { font-size: large; }
    .font-size-md-x-large { font-size: x-large; }
    .font-size-md-xx-large { font-size: xx-large; }
}
  
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
    .font-size-lg-xx-small { font-size: xx-small; }
    .font-size-lg-x-small { font-size: x-small; }
    .font-size-lg-small { font-size: small; }
    .font-size-lg-medium { font-size: medium; }
    .font-size-lg-large { font-size: large; }
    .font-size-lg-x-large { font-size: x-large; }
    .font-size-lg-xx-large { font-size: xx-large; }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .font-size-xl-xx-small { font-size: xx-small; }
    .font-size-xl-x-small { font-size: x-small; }
    .font-size-xl-small { font-size: small; }
    .font-size-xl-medium { font-size: medium; }
    .font-size-xl-large { font-size: large; }
    .font-size-xl-x-large { font-size: x-large; }
    .font-size-xl-xx-large { font-size: xx-large; }
}

// General Flex Grow classes to be added to common file
@for $i from 2 through 10 {
    .flex-grow-#{$i} { flex-grow: $i!important; }
}
 
$display5-size:               2.5rem !default;
$display6-size:               2rem !default;
$display7-size:               1.5rem !default;
$display8-size:               1rem !default;
$display9-size:               0.75rem !default;
$display10-size:              0.6rem !default;
$display5-weight:             300 !default;
$display6-weight:             300 !default;
$display7-weight:             500 !default;
$display8-weight:             200 !default;
$display9-weight:             100 !default;
$display10-weight:            100 !default;
.display-5 {
    @include font-size($display5-size);
    font-weight: $display5-weight;
    line-height: $display-line-height;
}
.display-6 {
    @include font-size($display6-size);
    font-weight: $display6-weight;
    line-height: $display-line-height;
}
.display-7 {
    @include font-size($display7-size);
    font-weight: $display7-weight;
    line-height: $display-line-height;
}
.display-8 {
    @include font-size($display8-size);
    font-weight: $display8-weight;
    line-height: $display-line-height;
}
.display-9 {
    @include font-size($display9-size);
    font-weight: $display9-weight;
    line-height: $display-line-height;
}
.display-10 {
    @include font-size($display10-size);
    font-weight: $display10-weight;
    line-height: $display-line-height;
}

$base-box-height: 100px;
$base-box-width: 100px;
@for $i from 0 through 20 {
    @if $i > 0 {
        .h-#{$i} { height: $base-box-height * $i; }
        .h-min-#{$i} { min-height: $base-box-height * $i }
        .h-max-#{$i} { max-height: $base-box-height * $i }
        .w-#{$i} { width: $base-box-width * $i }
        .w-min-#{$i} { min-width: $base-box-width * $i }
        .w-max-#{$i} { max-width: $base-box-width * $i }
    }
    .h-#{$i}-5 { height: $base-box-height * ($i + 0.5); }
    .h-min-#{$i}-5 { min-height: $base-box-height * ($i + 0.5) }
    .h-max-#{$i}-5 { max-height: $base-box-height * ($i + 0.5) }
    .w-#{$i}-5 { width: $base-box-width * ($i + 0.5) }
    .w-min-#{$i}-5 { min-width: $base-box-width * ($i + 0.5) }
    .w-max-#{$i}-5 { max-width: $base-box-width * ($i + 0.5) }

    @if $i > 0 {
        .h-#{$i}-force { height: $base-box-height * $i !important }
        .h-min-#{$i}-force { min-height: $base-box-height * $i !important }
        .h-max-#{$i}-force { max-height: $base-box-height * $i !important }
        .w-#{$i}-force { width: $base-box-width * $i !important }
        .w-min-#{$i}-force { min-width: $base-box-width * $i !important }
        .w-max-#{$i}-force { max-width: $base-box-width * $i !important }
    }
    .h-#{$i}-5-force { height: $base-box-height * ($i + 0.5) !important }
    .h-min-#{$i}-5-force { min-height: $base-box-height * ($i + 0.5) !important }
    .h-max-#{$i}-5-force { max-height: $base-box-height * ($i + 0.5) !important }
    .w-#{$i}-5-force { width: $base-box-width * ($i + 0.5) !important }
    .w-min-#{$i}-5-force { min-width: $base-box-width * ($i + 0.5) !important }
    .w-max-#{$i}-5-force { max-width: $base-box-width * ($i + 0.5) !important }
}

.clickable { cursor: pointer; }

.overflow-x-auto { overflow-x: auto !important }
.overflow-y-auto { overflow-y: auto !important }
.overflow-x-hidden { overflow-x: hidden !important }
.overflow-y-hidden { overflow-y: hidden !important }

@for $i from 1 through 9 {
    .font-weight-#{$i}00 { font-weight: $i * 100; }
}

.text-expanded-small { letter-spacing: 1px; }
.text-expanded { letter-spacing: 2px; }
.text-expanded-large { letter-spacing: 3px; }

@for $i from 0 through 10 {
    .opacity-#{$i} { opacity: $i * 0.1 }
}
.opacity-secondary {
    @extend .opacity-7;
}
.opacity-non-essential {
    @extend .opacity-3;
}
.opacity-disabled {
    @extend .opacity-2;
}

.pulse {
    animation: pulse 5s ease-in-out infinite;
}
@keyframes pulse {
    0% { opacity: 1; }
    10% { opacity: 0.8; }
    15% { opacity: 1; }
}
.pulse-double {
    animation: pulse-double 3s ease-in-out infinite;
}
@keyframes pulse-double {
    0% { opacity: 1; }
    5% { opacity: 0.8; }
    10% { opacity: 1; }
    15% { opacity: 0.8; }
    20% { opacity: 1; }
}
.throb {
    animation: throb 2s ease-in alternate-reverse infinite;
}
.throb-slow {
    animation: throb 4s ease-in alternate-reverse infinite;
}
.throb-reverse {
    animation: throb-reverse 2s ease-out alternate-reverse infinite;
}
@keyframes throb {
    0% { opacity: 1; }
    50% { opacity: 0.2; }
    100% { opacity: 1; }
}
@keyframes throb-reverse {
    0% { opacity: 0.2; }
    50% { opacity: 1; }
    100% { opacity: 0.2; }
}
.rotate {
    animation: rotation 2s infinite linear;
}
@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
// .bg-white {
//   background-color: #fff !important;
// }
// .bg-secondary {
//   background-color: #4343b0 !important;
// }
