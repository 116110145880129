@import "../../utils/themes/common/variables";

.integrations-library__search-bar {
  max-width: 500px;
}

.integrations-library__cards {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.integrations-library__card {
  flex: 1 0 auto;
  position: relative;
  max-width: 264px;
}

.integrations-library__card--error {
  background-color: red;
  border: 1px solid red;
}

.integrations-library__card-badges {
  position: absolute;
  top: 0.2em;
  right: 0.2em;

  &--relative {
    position: relative;
    left: 0;
    top: 0;
  }

  .badge {
    padding: 1em;

    & + .badge {
      margin-left: 8px;
    }
  }
}

.integrations-library__card-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 128px;
  width: 200px;
  padding: 8px 16px;
  margin: 0 auto;

  img {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  &--small {
    padding: 4px;
    height: 60px;
    width: 120px;
  }

  &--avatar {
    padding: 4px;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    max-width: 32px;
    width: 32px;
    
    img, svg {
      display: block;
      height: 16px;
      width: 16px;
    }
  }

  &--badge {
    height: 32px;
    width: 32px;
    border-radius: 0;
    margin-right: 8px;
    border-radius: 0;
  }
}

.integrations-library__card-error {
  display: flex;
  align-items: center;
  
  span {
    text-decoration: underline;
  }
}

.integrations-library__card-description {
  height: 150px;
  overflow-y: auto;
  padding: 4px 8px;

  p {
    white-space: pre-line;
  }
}

.integrations-library__upgrade-confirm {
  &#{&}#{&}#{&} {
    width: 300px;
    max-width: 300px;
  }
}

.integrations-library__connectors-list {
  max-height: 250px;
  overflow-y: auto;

  li {
    align-items: center;
  }
}

.integration-library__progress-dialog {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  .content {
    padding-inline: 16px;
  }
}

.bp3-dialog.modal-container {
  margin-top: 0;

  .bp3-dialog-body {
    padding-left: 4px;
    padding-right: 4px;
    min-height: 400px;
    overflow-y: auto;
  }
}

/* Icon inside the add node menu */
.bp3-menu-item .integrations-library__card-logo.integrations-library__card-logo--avatar {
  border-radius: 8px !important;
  padding: 4px;
}

/* Styles for dark theme */
html[theme="dark"] .integrations-library__card-logo {
  background: white;
  border-radius: 16px;

  &--avatar {
    background: transparent;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &--badge {
    border-radius: 0;
  }
}

html[theme="dark"] .integration-library__card-control-btn:disabled .bp3-button-text {
  color: white;
}