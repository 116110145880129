@import 'variables.scss';
html .page-with-header {
    .page-content {
        padding: 0;
        min-height: 400px;
        &.add-padding {
            padding: 1rem 3rem;
        }
    }
    .page-header {
        z-index: 5;
      .#{$ns}-divider {
        height: 25px;
      }
    }
}
