.navbar-logo {
  height: 36px;
  margin-top: 6px;
}
// @media (min-width: 576px) {
//   // .navbar-logo {
//   //   // margin-left: -16px !important;
//   // }
// }
.dark-background input {
    background: #161A25 !important;
    color: #adb5bd !important;
    border: 1px solid #404244 !important;
}

.bp3-navbar.main-navbar .simple-tooltip-target .simple-tooltip {
  width: 100px !important;
}

.bp3-navbar-heading {
  margin-left: 10px;
}

.bp3-navbar-divider {
  height: 50px !important;
  margin: 0 5px 0 8px !important;
  border-left-color: #454545 !important;
}

.hamburger-btn {
  padding: 10px 15px;
  margin-left: -0px !important
  ;
}

.bp3-navbar {
  padding-left: 10px !important;
}
