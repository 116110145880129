@import "../variables";
.sparkline-tile {
    .card-chart-holder {
        min-width: $sparkline-chart-min-width;
        min-height: $sparkline-chart-min-height;
    }
    .highcharts-container {
        display: inline-block;
    }
}
