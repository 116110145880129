.detailsFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px;
}

.detailsFields .info {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
