.#{$ns}-input:not([type="search"]) {
  border-radius: 0 !important;
}

.#{$ns}-control.#{$ns}-checkbox .#{$ns}-control-indicator {
  border-radius: 0 !important;
}

.#{$ns}-html-select select,
.#{$ns}-select select {
  border-radius: 0;
}
