@import "utils/themes/common/variables";

html[theme=dark] {
    .netimEdgeText > rect {
        fill: $dark-gray4;
    }

    .netimEdgeText > text {
        fill: #FFFFFF;
    }
}

html[theme=light] {
    .netimEdgeText > rect {
        fill: $light-gray4;
    }

    .netimEdgeText > text {
        fill: #242F3E;
    }
}
