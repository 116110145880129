.graph-error-header {
    color: #666666;
    font-size: 1.8em;
    height: 60px;
    line-height: 60px;
    margin: 0;
    box-sizing: border-box;
    padding-left: 65px;
}

.graph-error-content {
    overflow-y: auto;
    height: calc(100% - 70px);
    padding: 0 25px;
}

.warning-error-label .bp3-tree-node-content {
    min-height: 30px;
    height: unset !important;
}

.warning-error-label .icon-and-label-div {
    white-space: break-spaces !important;
}
