html[theme=light] {
    // Importing theme-specific overrides under light mode
    @import 'variables-light';
    
    // Importing bootstrap and blueprint so that it gets compiled with above variable overrides
    @import '../common/bootstrap-imports';
    @import '../common/blueprint-imports';
        
    // Importing common CSS class overrides 
    @import '../common/styles';
}

html[theme=dark] {
    // Importing theme-specific overrides under dark mode
    @import 'variables-dark';
    
    // Importing bootstrap and blueprint so that it gets compiled with above variable overrides
    @import '../common/bootstrap-imports';
    @import '../common/blueprint-imports';
    
    // Importing common CSS class overrides
    @import '../common/styles';
}
