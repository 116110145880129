@import "utils/themes/common/variables";
.add-text {
    text-decoration: underline;
    margin-left: 10px;
}

html[theme=dark] {
    .compare-control {
        background-color: $dark-gray4;
    }
}

html[theme=light] {
    .compare-control {
        background-color: $light-gray4
    }
}

.fn-operator {
    width: 100px;
}

.exp-unit {
    min-width: 75px;
}

.exp-value {
    min-width: 100px;
}

.exp-operator {
    min-width: 50px;
}

.exp-fn-operator {
    min-width: 50px;
}

.exp-attribute {
    min-width: 200px;
}