@import "../../../../utils/themes/common/variables";
.react-flow-component {
    .toolbar {
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        min-height: 60px;
        line-height: 40px;
        padding: 0 5px;
        box-sizing: border-box;
        user-select: none;
        z-index: 5;
    }
    &.graph-component-container .layoutflow {
        width: 100%;
        height: 100%;
    }
    &.graph-static .graph-component-container .layoutflow {
        padding-left: 0;
        top: 0;
    }
    &.graph-static .graph-component-container {
        .react-flow {
          // Allow pass-through of user's mouse scroll action. Without this, the scroll action gets caught in the react flow chart
          pointer-events: none;
        }
        .react-flow__nodes>*:not(.node-warnings-and-errors) {
            pointer-events: none !important;
        }
    }
    @media screen and (max-height: 500px) {
        .toolbar {
            top: 30px;
            height: 30px;
            line-height: 30px;
        }
    }
    .layoutflow {
        flex-grow: 1;
        position: relative;
        width: 100%;
        height: 100%;
    }
    .react-flow__node {
        box-shadow: 2px 2px 5px #00000040;
        a {
            font-weight: 700;
            color: #111;
        }
        .dragging {
            z-index: 1000;
        }
    }
    .react-flow__node-selectorNode {
        font-size: 12px;
        background: #f0f2f3;
        border: 1px solid 555;
        border-radius: 5px;
        text-align: center;
    }
    .react-flow__node-selectorNode .react-flow__handle {
        border-color: #f0f2f3;
    }
    .react-flow__edge {
        cursor: pointer;
        opacity: 0.5;
        &.selected {
            opacity: 1;
        }
    }
    .react-flow__edge-path {
        stroke: #b1b1b7;
        stroke-width: 3;
    }
    @media screen and (min-width: 768px) {
        .overview-example__add {
            display: block;
        }
    }

    .staticked {
      .react-flow__edge, 
      .react-flow__edge-textwrapper,
      .node-warnings-and-errors {
        pointer-events: none !important;
      }
      .react-flow__handle {
        &.target {
          pointer-events: none;
        }
        &.source-plus-handle {
          pointer-events: none;
        }
      }
    }
    
    .react-flow__node-default,
    .react-flow__node-input,
    .react-flow__node-output,
    .react-flow__node-subflow,
    .react-flow__node-switch,
    .react-flow__node-logic,
    .react-flow__node-comment,
    .react-flow__node-decision {
        padding: 0;
        border-radius: 7px;
        border: none;
        .contents {
            min-height: 30px;
            display: flex !important;
            align-items: stretch !important;
            justify-content: center;
            overflow-wrap: anywhere;
            .icon-and-label {
                display: flex;
                flex-grow: 1;
                &>.icon,
                >.name {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }
                &>.icon {
                    border-radius: 7px 0 0 7px;
                    padding-left: 7px;
                    padding-right: 7px;
                    background: rgba(0, 0, 0, 0.3);
                    color: #FFF;
                }
                &>.name {
                    padding: 3px;
                }
            }
        }
        .react-flow__handle {
            background: #b1b1b7;
            width: 10px;
            height: 10px;
            &.target {
                transition: width 0.1s, height 0.1s, left 0.1s, right 0.1s;
                &:hover {
                    height: 20px;
                    width: 20px;
                    left: -10px;
                    z-index: 10;
                }
            }
            &.source-plus-handle {
              font-size: 15px;
              display: block;
              width: 14px;
              height: 14px;
              border-radius: 50%;
              border: 1.5px solid darkgrey;
              background-color: white;
              line-height: 16px;
              text-align: center;
              box-sizing: border-box;
              &:hover {
                transition: width 0.2s, height 0.2s, font-size 0.2s, line-height 0.2s;
                font-size: 24px;
                width: 23px;
                height: 23px;
                line-height: 25px;
                &:after  {
                  left: 3px;
                }
              }
          }
          &.source-plus-handle:after {
            content: "+";
            position: absolute;
            font-weight: 700;
            top: -3px;
            left: 1px;
            color: darkgrey;
          }
      }
        &.selected, &.selected:hover {
            box-shadow: 0 0 5px 1px #cb8824;
            border: 1px solid #FFF;
        }
    }
    .react-flow__node-default.comment {
      border: 1px solid #AAA !important;
    }
    .react-flow__node-comment {
        border-radius: 0;
        width: unset;
        min-width: 150px;
        max-width: 250px;
        .contents .icon-and-label {
            .icon {
                border-radius: 0;
            }
            .name {
                padding: 20px 10px;
            }
        }
    }
    .react-flow__node-subflow {
        border-radius: 10px 0 10px 10px;
        
        .contents {
            .icon {
                border-radius: 7px 0 0 0;
            }
            .name {
                padding: 10px
            }
        }
    }
    .react-flow__node-logic {
        .contents {
            .name {
                padding-right: 18px;
            }
        }
  }
    .react-flow__node-logic .react-flow__handle {
        background: #b1b1b7;
        width: 10px;
        height: 10px;
    }
    .connection-in-progress .react-flow__node:not(.connection-in-progress-source):hover .react-flow__handle.target {
        height: 20px;
        width: 20px;
        left: -10px;
        z-index: 10;
    }

    .simple-tooltip-target .simple-tooltip {
      .simple-tooltip-content {
        top: 8px;
      }
      .simple-tooltip-content.rhs {
        left: -90px;
      }
    }

}

html[theme=dark] {
    .react-flow-component {
        .react-flow__node {
            box-shadow: 2px 2px 5px #000000;
        }
        .react-flow__node-default,
        .react-flow__node-input,
        .react-flow__node-output {
            &.selected, &.selected:hover {
                box-shadow: 0 0 5px 1px #cb8824;
                border: 1px solid #FFF;
            }
            .bp3-icon-help {
                color: #a7b6c2;
            }
        }
    }
}

[theme=light] .ic-azure-monitor svg {
  fill: #e9ecef !important;
}

[theme=dark] .ic-azure-monitor svg {
    fill: #242833 !important;
}

.bp3-submenu > * > .bp3-menu {
    max-height: 96vh;
    overflow: auto;
}
