@import "../../../utils/themes/common/variables";
aside#drag-and-drop-panel {
    user-select: none;
    .node-wrapper {
        cursor: grab;
        &.default,
        &.input,
        &.output,
        &.subflow {
            border-color: #a0a0a0;
            border-radius: 10px;
        }
        .tooltip-holder {
            opacity: 0;
            transition: opacity $transition-speed-1;
        }
        .tooltip-holder:has(.bp3-active) {
            opacity: 1;
        }
        &:hover:not(.dragging) .tooltip-holder {
            opacity: 1;
        }
        &.dragging .tooltip-holder {
            transition: none;
        }
    }
    .draggable-node {
        /* Had to add this transform so that when dragging, nodes with rounded borders, the background doesnt show up as white */
        transform: translate(0, 0);
    }

    // Show/hide categories tooltip
    .categories {
      & > span {
        opacity: 0;
        &:has(.bp3-active) {
          opacity: 1;
        }
      }
      &:hover {
        & > span {
          opacity: 1;
        }
      }
    } 
    .builtin-subflow-icon {
      background: transparent;
      width: 27px;
    }
}
// Hide hints when user starts dragging a node from the panel
.drag-in-progress .drag-and-drop-hint {
    display: none;
}
.variable-definitions-trigger {
    background-color: #CF9FFF;
    color: white;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // Ignore the parent padding
    width: calc(100% + 16px);
    margin: -8px;
}
