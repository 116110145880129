
body .tir-ui-table-container table.tir-ui-table.bp3-html-table.bp3-html-table-condensed tbody tr td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
body .user-impact-list-card .tir-ui-table-container table.tir-ui-table.bp3-html-table.bp3-html-table-bordered tbody {
    box-shadow: none !important;
    background: transparent !important;
    tr:not(:first-child) {
        border-top: none;
    }
    td:first-child {
      box-shadow: none;
    }
}

.overflow-elipsis-table-cell {
    text-align: left;
    text-overflow: ellipsis;
}


.user-impact-list-card .card-body {
  container: userSummaryCardContainer / inline-size;
}

.summary-user-widget-max-width {
  max-width: 430px;
}

@container userSummaryCardContainer (width > 500px) {
  .summary-user-widget-max-width {
    max-width: 415px;
  }
}
@container userSummaryCardContainer (width < 500px) and (width > 450px) {
  .summary-user-widget-max-width {
    max-width: 345px;
  }
}
@container userSummaryCardContainer (width < 450px) {
  .summary-user-widget-max-width {
    max-width: 255px;
  }
}


.user-impact-view .tir-ui-table-container {
  padding-top: 0 !important;
}
