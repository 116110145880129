.react-flow__node-comment {
    background: #fff;
}

.react-flow__node-comment {
    padding: 10px;
    border-radius: 0;
    width: 150px;
    font-size: 12px;
    color: #222;
    text-align: start;
    border-width: 1px;
    border-style: solid;
}

.react-flow__node-comment.selected, .react-flow__node-comment.selected:hover {
    /*    box-shadow: 0 0 0 0.5px #00ff72;*/
    box-shadow: 0 0 0 1px #1a192b;
}

/*
.react-flow__node-comment.selectable:hover {
    box-shadow: 0 1px 4px 1px rgb(0 0 0 / 8%);
}
*/
    